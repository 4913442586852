import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  BarChart,
  numberToQuantity,
  defaultGridProps,
  IAxisTick,
} from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TopInfluencersChart.module.less";

import { ITopInfluencersChartProps, IChartLabelClickEvent } from "./TopInfluencersChart.type";
import { stripePattern, getSentimentBars } from "../../helpers";
import { ITopInfluencersChartItem } from "@store/overview/types";

const TopInfluencersChart: FC<ITopInfluencersChartProps> = ({
  data,
  sentiment,
  chartWidth,
  filterData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const generateLabel = (props: IAxisTick) => {
    return (
      <Tooltip title={props.payload.value}>
        <foreignObject
          x={5}
          y={props.y - 12}
          cursor="pointer"
          data-cy="top-influencers-chart-label"
          className={s.bbTopInfluencersChartLabel}
        >
          <div className={s.bbTopInfluencersChartLabelContainer}>
            <div className={s.bbTopInfluencersChartLabelContent}>{props.payload.value}</div>
          </div>
        </foreignObject>
      </Tooltip>
    );
  };

  const generateChartData = (items: ITopInfluencersChartItem[]) => {
    let chartData = [];

    if (sentiment === "all") {
      chartData = items.map((item: ITopInfluencersChartItem) => {
        return {
          label: item.label,
          [t(`components:topInfluencers:charts:positive`)]: item.sentiment.positive,
          [t(`components:topInfluencers:charts:negative`)]: item.sentiment.negative,
          [t(`components:topInfluencers:charts:user_tags`)]: item.sentiment.user_tags,
          [t(`components:topInfluencers:charts:neutral`)]: item.sentiment.neutral,
          [t(`components:topInfluencers:charts:not_applicable`)]: item.sentiment.not_applicable,
        };
      });
    } else {
      chartData = items.map((item: ITopInfluencersChartItem) => {
        return {
          label: item.label,
          [t(`components:topInfluencers:charts:${sentiment}`)]: item.count,
        };
      });
    }

    return chartData;
  };

  const onLabelClick = (event: IChartLabelClickEvent) => {
    navigate(
      {
        search: "?section=listen-sentiment_details",
      },
      {
        state: {
          filters: {
            ...filterData,
            author_ids: event?.index !== undefined ? [data[event.index].id] : [],
          },
        },
      },
    );
  };

  return (
    <div className={s.bbTopInfluencersChart} data-cy="top-influencers-chart">
      <BarChart
        grid
        yAxis
        xAxis
        tooltip
        height={365}
        barSize={13}
        pattern={stripePattern}
        isShowPercent
        layout="vertical"
        stackOffset="sign"
        width={chartWidth - 50}
        percentagePrecision={1}
        gridProps={defaultGridProps}
        data={generateChartData(data)}
        tooltipProps={{ cursor: { fill: "transparent" } }}
        xAxisProps={{
          tickFormatter: numberToQuantity,
          type: "number",
        }}
        yAxisProps={{
          tickFormatter: numberToQuantity,
          dataKey: "label",
          type: "category",
          interval: 0,
          width: 90,
          tick: generateLabel,
          onClick: onLabelClick,
        }}
        bars={getSentimentBars()}
        margin={{ top: 5, right: 40, left: 40, bottom: 5 }}
      />
    </div>
  );
};

export default TopInfluencersChart;
