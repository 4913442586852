import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { IFeatureVisibilityFlagsState } from "./types";

const initialState: IFeatureVisibilityFlagsState = {
  featureVisibilityFlags: [],
  visibilityOptions: [],

  fetchingFeatureVisibilityFlags: false,
  fetchedFeatureVisibilityFlags: false,
  fetchedFeatureVisibilityFlagsFailed: false,

  updatingFeatureVisibilityFlags: false,
  updatedFeatureVisibilityFlags: false,
  updatingFeatureVisibilityFlagsFailed: false,

  fetchingFeatureVisibilityOptions: false,
  fetchedFeatureVisibilityOptions: false,
  fetchedFeatureVisibilityOptionsFailed: false,
};

export const featureVisibilityFlagsReducer = createReducer<
  IFeatureVisibilityFlagsState,
  ActionType<typeof actions>
>(initialState)
  .handleAction(actions.getFeatureVisibilityFlagsAction, state =>
    produce(state, draft => {
      draft.fetchingFeatureVisibilityFlags = true;
      draft.fetchedFeatureVisibilityFlags = false;
      draft.fetchedFeatureVisibilityFlagsFailed = false;
    }),
  )
  .handleAction(actions.getFeatureVisibilityFlagsSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.featureVisibilityFlags = action.payload;
      draft.fetchingFeatureVisibilityFlags = false;
      draft.fetchedFeatureVisibilityFlags = true;
      draft.fetchedFeatureVisibilityFlagsFailed = false;
    }),
  )
  .handleAction(actions.getFeatureVisibilityFlagsFailureAction, state =>
    produce(state, draft => {
      draft.featureVisibilityFlags = [];
      draft.fetchingFeatureVisibilityFlags = false;
      draft.fetchedFeatureVisibilityFlags = false;
      draft.fetchedFeatureVisibilityFlagsFailed = true;
    }),
  )
  .handleAction(actions.updateFeatureVisibilityFlagsAction, state =>
    produce(state, draft => {
      draft.updatingFeatureVisibilityFlags = true;
      draft.updatedFeatureVisibilityFlags = false;
      draft.updatingFeatureVisibilityFlagsFailed = false;
    }),
  )
  .handleAction(actions.updateFeatureVisibilityFlagsSuccessAction, state =>
    produce(state, draft => {
      draft.updatingFeatureVisibilityFlags = false;
      draft.updatedFeatureVisibilityFlags = true;
      draft.updatingFeatureVisibilityFlagsFailed = false;
    }),
  )
  .handleAction(actions.updateFeatureVisibilityFlagsFailureAction, state =>
    produce(state, draft => {
      draft.updatingFeatureVisibilityFlags = false;
      draft.updatedFeatureVisibilityFlags = false;
      draft.updatingFeatureVisibilityFlagsFailed = true;
    }),
  )
  .handleAction(actions.getFeatureVisibilityOptionsAction, state =>
    produce(state, draft => {
      draft.fetchingFeatureVisibilityOptions = true;
      draft.fetchedFeatureVisibilityOptions = false;
      draft.fetchedFeatureVisibilityOptionsFailed = false;
    }),
  )
  .handleAction(actions.getFeatureVisibilityOptionsSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.visibilityOptions = action.payload;
      draft.fetchingFeatureVisibilityOptions = false;
      draft.fetchedFeatureVisibilityOptions = true;
      draft.fetchedFeatureVisibilityOptionsFailed = false;
    }),
  )
  .handleAction(actions.getFeatureVisibilityOptionsFailureAction, state =>
    produce(state, draft => {
      draft.visibilityOptions = [];
      draft.fetchingFeatureVisibilityOptions = false;
      draft.fetchedFeatureVisibilityOptions = false;
      draft.fetchedFeatureVisibilityOptionsFailed = true;
    }),
  );
