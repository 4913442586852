import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import PostsTableRow from "./PostsTableRow";
import { SortType, SortableTable, ISortableTableColumn } from "@bbdevcrew/bb_ui_kit_fe";

import {
  getPostsSelector,
  getPaginationSelector,
  getPostsPendingSelector,
} from "@store/posts/selectors";
import { getPostsAction } from "@store/posts/actions";

import s from "./PostsTable.module.less";

import { getPostsTableColumns } from "./helpers";
import { IFilters } from "@store/filters/types";
import { IPost } from "../../../_common/Social/Post/Post.type";
import { IPostSort, IPostsPayload, PostColumnSortType } from "@store/posts/types";

interface IPostsTableProps {
  filters: IFilters;
}

const PostsTable: FC<IPostsTableProps> = ({ filters }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const columns = useMemo(() => getPostsTableColumns(), []);
  const getPosts = useCallback((data: IPostsPayload) => dispatch(getPostsAction(data)), [dispatch]);

  const [sort, setSort] = useState<IPostSort>();
  const [page, setPage] = useState<number>(1);

  const posts = useSelector(getPostsSelector);
  const pagination = useSelector(getPaginationSelector);
  const postsPending = useSelector(getPostsPendingSelector);

  useEffect(() => {
    getPosts({
      filters,
      sort: sort,
      pagination: { page },
    });
  }, [sort, page, filters, getPosts]);

  const onSort = (column: ISortableTableColumn, _sort?: SortType) => {
    if (!_sort) {
      setSort(undefined);
    } else {
      setSort({
        order: _sort,
        field: column.id_name as PostColumnSortType,
      });
    }
  };

  return (
    <div className={s.bbPostsTable} data-cy="posts-table" id="post-overlay">
      <div className={s.bbPostsTableCol}>
        <SortableTable<IPost>
          data={posts}
          onSort={onSort}
          loading={postsPending}
          tableRow={PostsTableRow}
          columns={columns}
          pagination={{
            total: pagination?.total || 0,
            pageSize: pagination?.size,
            currentPage: pagination?.page,
            onChangePage: setPage,
          }}
          emptyTableText={t("generic:emptyTable")}
          pageOutOfText={[
            t("components:sortableTable:pageOutOfPart1"),
            t("components:sortableTable:pageOutOfPart1"),
          ]}
          customClassName={s.bbPostsTableWrapper}
        />
      </div>
    </div>
  );
};

export default PostsTable;
