import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";

import ColorDot from "../ColorDot";
import ClientPlanBadge from "./ClientPlanBadge";
import { Tooltip, SimpleSelectTrigger } from "@bbdevcrew/bb_ui_kit_fe";
import { SavedFilter } from "../AppFilter/filters/SavedFilters/SavedFilter";

import { meSelector } from "@store/me/selectors";
import { savedFilterValueSelector } from "@store/filters/selectors";
import { getModuleSectionsListAction } from "@store/modules/actions";
import { getAllModulesSectionsSelector } from "@store/modules/selectors";

import s from "./AppSidebar.module.less";

import { cropText } from "@utils/cropText";
import { getCurrentModuleName } from "@utils/paths";
import { IAppSidebarProps } from "./AppSidebar.types";
import { IAvailableSectionsType } from "@store/modules/types";
import { ModuleIconMap } from "./helpers";
import { useSavedFiltersList } from "@utils/useSavedFiltersList";

import { ChevronLeftIcon, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { UnlockIcon } from "@assets/index";

enum SidebarPanelStateEnum {
  EXPANDED,
  COLLAPSED,
  COLLAPSE_PREVIEW,
}

const AppSidebar: FC<IAppSidebarProps> = ({ hasSavedFilters, onSavedFilterChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const savedFiltersList = useSavedFiltersList();
  const [searchParams, setSearchParams] = useSearchParams();

  const allConversationsText = t(
    "pages:dashboard:modals:savesSearch:filterSelector:allConversations",
  );

  const moduleId = getCurrentModuleName();
  const [sidebarPanelState, setSidebarPanelState] = useState<SidebarPanelStateEnum>(
    SidebarPanelStateEnum.EXPANDED,
  );

  const me = useSelector(meSelector);
  const savedFilterValue = useSelector(savedFilterValueSelector);
  const modulesSections = useSelector(getAllModulesSectionsSelector);

  const sections = modulesSections[moduleId as IAvailableSectionsType];

  const isCollapsed = sidebarPanelState === SidebarPanelStateEnum.COLLAPSED;
  const isExpanded = sidebarPanelState === SidebarPanelStateEnum.EXPANDED;
  const isPreviewMode = sidebarPanelState === SidebarPanelStateEnum.COLLAPSE_PREVIEW;

  const collapsePanel = () => setSidebarPanelState(SidebarPanelStateEnum.COLLAPSED);
  const expandPanel = () => setSidebarPanelState(SidebarPanelStateEnum.EXPANDED);
  const previewPanel = () => setSidebarPanelState(SidebarPanelStateEnum.COLLAPSE_PREVIEW);

  const getModuleSectionsList = useCallback(
    (module_id: string) => dispatch(getModuleSectionsListAction(module_id)),
    [dispatch],
  );

  const setActiveSection = useCallback(
    (id: string) => {
      const newParams = new URLSearchParams(new URL(document.location.href).searchParams);
      newParams.set("section", id);
      setSearchParams(newParams);
    },
    [setSearchParams],
  );

  useEffect(() => {
    getModuleSectionsList(moduleId);
  }, [moduleId, getModuleSectionsList]);

  useEffect(() => {
    const sectionId = searchParams.get("section");

    if (!sectionId) {
      const DEFAULT_SECTION = sections
        ?.flatMap(section => section.features)
        .find(feature => feature.selected)?.id;

      if (DEFAULT_SECTION) setActiveSection(DEFAULT_SECTION);
    }
  }, [searchParams, setActiveSection, sections]);

  const savedFilterItem = useMemo(() => {
    return savedFiltersList.find(filter => filter.id.toString() === savedFilterValue?.toString());
  }, [savedFiltersList, savedFilterValue]);

  return (
    <div
      className={classNames(s.bbAppSidebarWrapper, {
        [s.bbAppSidebarCollapsedWrapper]: isCollapsed || isPreviewMode,
      })}
    >
      <div
        className={classNames(s.bbAppSidebar, {
          [s.bbAppSidebarFullWidth]: isPreviewMode || isExpanded,
        })}
        onMouseLeave={isPreviewMode ? collapsePanel : undefined}
      >
        <div
          className={s.bbAppSidebarSections}
          onMouseEnter={isCollapsed ? previewPanel : undefined}
        >
          {sections
            ?.filter(section => !!section.is_visible)
            .map((section, sectionIndex) => (
              <Fragment key={section.id}>
                <div key={section.id} className={s.bbAppSidebarSection}>
                  <div className={s.bbAppSidebarSectionLabel}>
                    {!isCollapsed ? section.label : ""}
                  </div>

                  {hasSavedFilters && sectionIndex === 0 && (
                    <SavedFilter onSavedChange={onSavedFilterChange} useInSidebar>
                      {({ open }) => (
                        <SimpleSelectTrigger
                          active={open}
                          className={classNames(s.bbSavedFiltersSelect, {
                            [s.bbSavedFiltersSelectCollapsed]: isCollapsed,
                          })}
                        >
                          {savedFilterValue === null ? (
                            <div className={s.bbSavedFiltersSelectValue}>
                              <ColorDot color="all" />
                              {!isCollapsed && allConversationsText}
                            </div>
                          ) : (
                            <div className={s.bbSavedFiltersSelectValue}>
                              {savedFilterItem?.color ? (
                                <ColorDot color={savedFilterItem?.color} />
                              ) : null}
                              {!isCollapsed && cropText(savedFilterItem?.name || "", 15)}
                            </div>
                          )}
                        </SimpleSelectTrigger>
                      )}
                    </SavedFilter>
                  )}

                  {section.features
                    ?.filter(feature => !!feature.is_visible)
                    .map(feature => (
                      <>
                        <Tooltip
                          title={
                            feature.is_locked
                              ? t(`components:listen:sidePanel:lockedFeatureTooltip`)
                              : undefined
                          }
                        >
                          <div
                            key={feature.id}
                            onClick={
                              !feature.is_locked ? () => setActiveSection(feature.id) : undefined
                            }
                            className={classNames(s.bbAppSidebarFeature, {
                              [s.bbAppSidebarFeatureActive]:
                                feature.id === searchParams.get("section") && !feature.is_locked,
                              [s.bbAppSidebarFeatureLocked]: feature.is_locked,
                            })}
                          >
                            {ModuleIconMap[moduleId][feature.id]}
                            <span className={s.bbAppSidebarFeatureLabel}>
                              {!isCollapsed ? feature.label : ""}
                            </span>
                            {feature.is_locked && !isCollapsed && (
                              <div className={s.bbAppSidebarFeatureLockedIcon}>
                                <UnlockIcon />
                              </div>
                            )}
                          </div>
                        </Tooltip>
                      </>
                    ))}
                </div>
                {sectionIndex !== sections.length - 1 && (
                  <div className={s.bbAppSidebarSectionDivider} />
                )}
              </Fragment>
            ))}
        </div>
        <div className={s.bbClientPlanBadgeWrapper}>
          {!!me?.client?.client_plan && (
            <div onMouseEnter={isCollapsed ? previewPanel : undefined}>
              <ClientPlanBadge type={me.client.client_plan} showTitle={!isCollapsed} />
            </div>
          )}
          {!isCollapsed && (
            <Tooltip
              title={t(
                `components:listen:sidePanel:${
                  isPreviewMode ? "expandTooltip" : "collapsibleTooltip"
                }`,
              )}
            >
              <div
                className={s.bbSidebarCollapseIcon}
                onClick={isPreviewMode ? expandPanel : collapsePanel}
              >
                {isPreviewMode ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppSidebar;
