import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import { useNavigate } from "react-router-dom";

import { Row, Col } from "antd";
import Header from "../header";
import SentimentsCards from "@components/insights/overview/sentimentsCards/SentimentsCards";
import EngagementsCards from "@components/insights/overview/engagementsCards/EngagementsCards";
import CommentEngagementCards from "@components/insights/overview/commentEngagementCards";
import PlatformsPieChart from "@components/insights/overview/platformsPieChart/PlatformsPieChart";
import SentimentPieChart from "@components/insights/overview/sentimentPieChart/SentimentPieChart";
import UserLanguages from "@components/insights/overview/userLanguages/UserLanguages";
import TopInfluencers from "@components/insights/overview/topInfluencers/TopInfluencers";
import MessageTypePieChart from "@components/insights/overview/messageTypePieChart/MessageTypePieChart"; // eslint-disable-line max-len
import MessageTypeDetailTable from "@components/insights/overview/messageTypeDetailTable/MessageTypeDetailTable"; // eslint-disable-line max-len
import MostCommentedPosts from "@components/insights/overview/mostCommentedPosts/MostCommentedPosts"; // eslint-disable-line max-len

import { filterDataSelector } from "@store/filters/selectors";
import {
  getDashboardSelectorPending,
  getDashboardSelectorSuccessful,
  overviewSentimentSelector,
  overviewPlatformsSelector,
  overviewMessageTypesSelector,
  overviewConversationsSelector,
  overviewLanguagesSelector,
  overviewTopInfluencersSelector,
} from "@store/dashboard/selectors";
import { meSuccessfulSelector } from "@store/me/selectors";

import { postDashboardAction } from "@store/dashboard/actions";

import s from "./Overview.module.less";

import { IDashboardPayload, OverviewComponentType } from "@store/dashboard/types";

import DownloadButton from "../downloadButton";

export const Overview: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [topSectionsLoading, setTopSectionsLoading] = useState(false);
  const [topSectionsLoaded, setTopSectionsLoaded] = useState(false);
  const [bottomSectionsLoading, setBottomSectionsLoading] = useState(false);

  const filters = useSelector(filterDataSelector);
  const meFetched = useSelector(meSuccessfulSelector);
  const dashboardLoading = useSelector(getDashboardSelectorPending);
  const dashboardLoaded = useSelector(getDashboardSelectorSuccessful);
  const overviewSentiments = useSelector(overviewSentimentSelector);
  const overviewPlatforms = useSelector(overviewPlatformsSelector);
  const overviewMessageType = useSelector(overviewMessageTypesSelector);
  const overviewConversations = useSelector(overviewConversationsSelector);
  const overviewLanguages = useSelector(overviewLanguagesSelector);
  const overviewTopInfluencers = useSelector(overviewTopInfluencersSelector);

  const getDashboardData = useCallback(
    (data: IDashboardPayload) => dispatch(postDashboardAction(data)),
    [dispatch],
  );

  const getTopSections = useCallback(() => {
    getDashboardData({
      filters,
      components: [
        "explorer_overview_sentiment",
        "explorer_overview_platform",
        "explorer_overview_conversations",
      ],
    });
  }, [filters, getDashboardData]);

  const getBottomSections = useCallback(() => {
    const components: OverviewComponentType[] = [
      "explorer_overview_top_languages",
      "explorer_overview_top_influencers",
      "explorer_overview_message_types",
    ];

    getDashboardData({
      filters,
      components,
    });
  }, [filters, getDashboardData]);

  useEffect(() => {
    if (meFetched && filters && !topSectionsLoading) {
      getTopSections();
      setTopSectionsLoading(true);
    }
    // eslint-disable-next-line
  }, [meFetched, filters, getTopSections]);

  useEffect(() => {
    if (dashboardLoaded && !dashboardLoading) {
      if (topSectionsLoading) {
        setTopSectionsLoading(false);
        setTopSectionsLoaded(true);
      }
      if (bottomSectionsLoading) {
        setBottomSectionsLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [dashboardLoading, dashboardLoaded]);

  useEffect(() => {
    if (!topSectionsLoading && topSectionsLoaded && !bottomSectionsLoading) {
      getBottomSections();
      setBottomSectionsLoading(true);
    }
    // eslint-disable-next-line
  }, [topSectionsLoading, topSectionsLoaded]);

  const handleSentimentCardClick = (type: string) => {
    navigate("?section=listen-sentiment_details", {
      state: {
        filters: {
          ...filters,
          sentiments: [type],
        },
      },
    });
  };

  return (
    <>
      <Header>
        <DownloadButton />
      </Header>
      <div className={s.bbOverviewContainer}>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <SentimentsCards
              data={overviewSentiments || []}
              loading={topSectionsLoading}
              filters={filters || {}}
              handleSentimentCardClick={handleSentimentCardClick}
            />
          </Col>
          <Col xs={24}>
            {overviewConversations?.length === 4 ? (
              <EngagementsCards data={overviewConversations || []} loading={topSectionsLoading} />
            ) : overviewConversations?.length === 2 ? (
              <CommentEngagementCards
                data={overviewConversations || []}
                loading={topSectionsLoading}
              />
            ) : null}
          </Col>
        </Row>

        <ReactResizeDetector
          handleWidth
          refreshMode="throttle"
          refreshOptions={{ leading: true, trailing: true }}
        >
          {(props: { width: number }) => (
            <MostCommentedPosts filters={filters || {}} containerWidth={props.width} />
          )}
        </ReactResizeDetector>

        <Row gutter={[24, 24]} className={s.bbPieChartsWrapper} id="overview-content-section">
          <Col xs={24} xl={12}>
            <SentimentPieChart
              data={overviewSentiments || []}
              filterData={filters || {}}
              loading={topSectionsLoading}
            />
          </Col>
          <Col xs={24} xl={12}>
            <PlatformsPieChart
              data={overviewPlatforms || []}
              loading={topSectionsLoading}
              filterData={filters || {}}
            />
          </Col>
        </Row>

        <Row gutter={[24, 24]} className={s.bbBottomSection}>
          <Col xs={24} xl={12}>
            <UserLanguages
              data={overviewLanguages || []}
              loading={bottomSectionsLoading}
              filterData={filters || {}}
            />
          </Col>
          <Col xs={24} xl={12}>
            <TopInfluencers
              data={overviewTopInfluencers || []}
              loading={bottomSectionsLoading}
              filterData={filters || {}}
            />
          </Col>

          <Col xs={24} xl={12}>
            <MessageTypePieChart
              data={overviewMessageType?.messageTypeDistribution}
              loading={bottomSectionsLoading}
              filterData={filters || {}}
            />
          </Col>
          <Col xs={24} xl={12}>
            <MessageTypeDetailTable
              data={overviewMessageType?.messageTypeDetail || []}
              loading={bottomSectionsLoading}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
