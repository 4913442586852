import React, { FC, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

import TagsDropdown from "../../TagsDropdown/TagsDropdown";
import CustomTagsTriggerButton from "./CustomTagsTriggerButton";

import {
  getCustomTagsAction,
  postCustomTagAction,
  deleteCustomTagAction,
} from "@store/customTags/actions";
import {
  customTagsSelector,
  postCustomTagSelector,
  fetchingCustomTagsSelector,
  getCustomTagsSuccessfulSelector,
  postCustomTagSuccessfulSelector,
  postCustomTagLastCreatedSelector,
} from "@store/customTags/selectors";

import s from "./CustomTags.module.less";

import { ICustomTagsProps } from "./CustomTags.type";
import { ICustomTag } from "../../TagsDropdown/TagsDropdown.type";
import { IPostCustomTagPayload } from "@store/customTags/types";

const CustomTagsDropdown: FC<ICustomTagsProps> = ({
  commentId,
  parentCommentId,
  hasTags = false,
  customTagsByUser = [],
  customTagsAutomated = [],
  type = "default",
  _dropdownPlacement = "bottomLeft",
  trigger: TriggerComponent,
  wrapperClassName,
  onSelectTag,
  listId,
}) => {
  const dispatch = useDispatch();

  const initialCustomTags = useSelector(customTagsSelector);
  const customTagsFetching = useSelector(fetchingCustomTagsSelector);
  const customTagsFetched = useSelector(getCustomTagsSuccessfulSelector);
  const customTagCreating = useSelector(postCustomTagSelector);
  const customTagCreated = useSelector(postCustomTagSuccessfulSelector);
  const lastCreatedTag = useSelector(postCustomTagLastCreatedSelector);

  const hasCustomTags = hasTags || !!customTagsByUser?.length || !!customTagsAutomated?.length;

  const fetchCustomTags = useCallback(() => dispatch(getCustomTagsAction()), [dispatch]);
  const postCustomTag = useCallback(
    (data: IPostCustomTagPayload) => dispatch(postCustomTagAction(data)),
    [dispatch],
  );
  const deleteCustomTagFromDropdown = useCallback(
    (id: string) => dispatch(deleteCustomTagAction(id)),
    [dispatch],
  );

  const _onSelectTag = (tag: ICustomTag) => {
    onSelectTag?.(tag);
    postCustomTag({
      label: tag.label,
      comment_id: commentId,
      parent_comment_id: parentCommentId,
      listId,
    });
  };

  const onAddNewTag = (tagLabel: string) => {
    postCustomTag({
      label: tagLabel,
      comment_id: commentId,
      parent_comment_id: parentCommentId,
      listId,
    });
  };

  const tagsStateData = {
    tagsLoading: customTagsFetching,
    tagsLoaded: customTagsFetched,
    tagCreating: customTagCreating,
    tagCreated: customTagCreated,
    lastCreatedTag: lastCreatedTag,
  };

  return (
    <div className={classNames(s.bbCustomTagsDropdownWrapper, wrapperClassName)}>
      <TagsDropdown
        id="customTags"
        tagsStateData={tagsStateData}
        initialTags={initialCustomTags}
        _dropdownPlacement={_dropdownPlacement}
        tags={[...customTagsByUser, ...customTagsAutomated]}
        onCreateTag={onAddNewTag}
        onSelectTag={_onSelectTag}
        onSelectTagCallback={onSelectTag}
        fetchTags={fetchCustomTags}
        deleteTagFromDropdown={deleteCustomTagFromDropdown}
        trigger={
          <CustomTagsTriggerButton
            type={type}
            hasCustomTags={hasCustomTags}
            trigger={TriggerComponent}
          />
        }
      />
    </div>
  );
};

export default CustomTagsDropdown;
