import React from "react";
import { useTranslation, Trans } from "react-i18next";

import classNames from "classnames";

import s from "./Tooltips.module.less";

const EngagementRateTooltipContent = () => {
  const { t } = useTranslation();
  const platforms = ["facebook", "instagram", "tiktok"];

  return (
    <div className={s.bbPostMetricTooltip}>
      {platforms.map(platform => (
        <div key={platform} className={s.bbPostMetricTooltipBlock}>
          <Trans
            i18nKey={"components:posts:table:tableHeader:tooltips:engagementRate." + platform}
            components={{
              strong: <span className={s.bbPostMetricTooltipBold} />,
            }}
          />
        </div>
      ))}
      <div className={s.bbPostMetricTooltipFooter}>
        {t("components:posts:table:tableHeader:tooltips:footer")}
      </div>
    </div>
  );
};

const ReachesTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <div className={s.bbPostMetricTooltip}>
      <div className={classNames(s.bbPostMetricTooltipBlock, s.bbPostMetricTooltipBold)}>
        {t("components:posts:table:tableHeader:tooltips:totalReaches:title")}
      </div>
      <div className={s.bbPostMetricTooltipBlock}>
        <Trans
          i18nKey={"components:posts:table:tableHeader:tooltips:totalReaches:tiktok"}
          components={{
            strong: <span className={s.bbPostMetricTooltipBold} />,
          }}
        />
      </div>
      <div className={s.bbPostMetricTooltipFooter}>
        {t("components:posts:table:tableHeader:tooltips:footer")}
      </div>
    </div>
  );
};

const ReactionsTooltipContent = () => {
  const { t } = useTranslation();
  const platforms = ["facebook", "instagram", "tiktok"];

  return (
    <div className={s.bbPostMetricTooltip}>
      {platforms.map(platform => (
        <div key={platform} className={s.bbPostMetricTooltipBlock}>
          <Trans
            i18nKey={"components:posts:table:tableHeader:tooltips:totalReactions." + platform}
            components={{
              strong: <span className={s.bbPostMetricTooltipBold} />,
            }}
          />
        </div>
      ))}
      <div className={s.bbPostMetricTooltipFooter}>
        {t("components:posts:table:tableHeader:tooltips:footer")}
      </div>
    </div>
  );
};

const SharesTooltipContent = () => {
  const { t } = useTranslation();
  const platforms = ["facebook", "instagram", "twitter", "youtube", "tiktok"];

  return (
    <div className={s.bbPostMetricTooltip}>
      {platforms.map(platform => (
        <div key={platform} className={s.bbPostMetricTooltipBlock}>
          <Trans
            i18nKey={"components:posts:table:tableHeader:tooltips:totalShares." + platform}
            components={{
              strong: <span className={s.bbPostMetricTooltipBold} />,
            }}
          />
        </div>
      ))}
      <div className={s.bbPostMetricTooltipFooter}>
        {t("components:posts:table:tableHeader:tooltips:footer")}
      </div>
    </div>
  );
};

export {
  EngagementRateTooltipContent,
  ReachesTooltipContent,
  ReactionsTooltipContent,
  SharesTooltipContent,
};
