import { i18n } from "i18next";
import { Auth } from "aws-amplify";
import { hotjar } from "react-hotjar";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import React, { FC, useEffect } from "react";
import { Integrations } from "@sentry/tracing";
import { I18nextProvider } from "react-i18next";
import { Authenticator } from "aws-amplify-react";
import { BrowserRouter as Router } from "react-router-dom";

import {
  BBConfirmSignInView,
  BBConfirmSignUpView,
  BBForgotPasswordView,
  BBRequireNewPasswordView,
  BBSignInView,
  BBSignUpView,
} from "@components/login";
import RoutesContainer from "@pages/Routes";

import "./App.less";
import "antd/dist/antd.less";

import { store } from "./store";
import { getI18next } from "./languages/i18next";
import { isTiktokApp } from "@utils/isTiktokApp";
import { detectUserOS } from "@utils/detectUserOS";
import { ToastProvider } from "@bbdevcrew/bb_ui_kit_fe";
import { BatchModeContextProvider } from "@containers/BatchMode/BatchMode";
import { deleteAllCookies, deleteCognitoCookies } from "@utils/cookies";

export const i18nextInstance = getI18next() as i18n;

export const isNotAnExceptionPage = () =>
  !window.location.pathname.includes("/login/tiktok") &&
  !window.location.pathname.includes("/account-deleted");

// Amplify Config
const cookieStorageConfig = {
  domain: process.env.AWS_AMPLIFY_COOKIE_STORAGE_DOMAIN,
  secure: process.env.AWS_AMPLIFY_COOKIE_STORAGE_SECURE === "true",
};

// eslint-disable-next-line
const amplifyConfig: any = {
  Auth: {
    //identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
    region: process.env.AWS_COGNITO_REGION,
    identityPoolRegion: process.env.AWS_COGNITO_IDENTITY_POOL_REGION,
    userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.AWS_OAUTH_DOMAIN,
      scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: process.env.AWS_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.AWS_OAUTH_REDIRECT_SIGN_OUT,
      responseType: process.env.AWS_OAUTH_RESPONSE_TYPE, // code
    },
    Analytics: {
      disabled: true,
    },
  },
};

const App: FC = () => {
  if (detectUserOS() === "Windows") {
    document.body.classList.add("custom-scrollbar");
  }

  if (isTiktokApp()) {
    document.body.classList.add("tt");
  }

  // Hotjar Config
  if (
    process.env.BUILD_ENV === "production" &&
    process.env.HOTJAR_ID &&
    process.env.HOTJAR_SOCKET_VERSION
  ) {
    hotjar.initialize(parseInt(process.env.HOTJAR_ID), parseInt(process.env.HOTJAR_SOCKET_VERSION));
  }

  // Sentry  Config
  if (process.env.SENTRY_URL) {
    Sentry.init({
      dsn: process.env.SENTRY_URL,
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],
      environment: process.env.BUILD_ENV,
      release: process.env.npm_package_version,

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }

  if (isTiktokApp()) {
    amplifyConfig.Auth = {
      ...amplifyConfig.Auth,
      storage: window.sessionStorage,
    };
  } else {
    amplifyConfig.Auth = {
      ...amplifyConfig.Auth,
      cookieStorage: cookieStorageConfig,
    };
  }

  const redirectToAppIfSSOLogin = () => {
    if (
      window.location.pathname === "/login/saml" &&
      localStorage.getItem("amplify-authenticator-authState") === "signedIn"
    ) {
      window.location.href = `${process.env.BASE_URL}/insights/?section=listen-overview`;
    }
  };

  const redirectToAppIfLandedOnBaseUrl = () => {
    if (
      (window.location.pathname === "/" ||
        window.location.pathname === "/insights" ||
        window.location.pathname === "/login") &&
      localStorage.getItem("amplify-authenticator-authState") === "signedIn"
    ) {
      const redirectUrl = `${
        localStorage.getItem("savedPreAuthURL") || "/insights/?section=listen-overview"
      }`;
      window.location.href = `${process.env.BASE_URL}${redirectUrl}`;
      localStorage.removeItem("savedPreAuthURL");
    }
  };

  const redirectInsightsRoutes = () => {
    const routesMapping: Record<string, string> = {
      "/insights/overview": "listen-overview",
      "/insights/search": "listen-sentiment_details",
      "/insights/compare": "report-compare",
      "/insights/posts": "report-posts",
    };

    const currentPath = window.location.pathname;
    const redirectSection = routesMapping[currentPath];

    if (redirectSection) {
      window.location.href = `${process.env.BASE_URL}/insights/?section=${redirectSection}`;
    } else if (currentPath === "/insights/notifications") {
      window.location.href = `${process.env.BASE_URL}/care/workflows`;
    }
  };

  useEffect(() => {
    const landedOnLoginPage =
      window.location.pathname.includes("/login") &&
      !window.location.pathname.includes("/login/saml");

    if (landedOnLoginPage) {
      deleteCognitoCookies();

      if (isTiktokApp() && isNotAnExceptionPage())
        window.location.href = `/login/tiktok${window.location.search}`;
    }

    redirectToAppIfSSOLogin();
    redirectToAppIfLandedOnBaseUrl();
    redirectInsightsRoutes();
  }, []);

  const setSignupState = () => {
    localStorage.setItem("amplify-authenticator-authState", "signUp");
  };

  const signOut = () => {
    Auth.currentUserInfo().then(user => {
      if (user.username) {
        Auth.signOut({ global: true })
          .then(() => {
            window.location.href = `${process.env.BASE_URL}/login/signup${window.location.search}`;
            setSignupState();
          })
          .catch(() => {
            deleteAllCookies();
            window.location.reload();
          });
      } else {
        deleteAllCookies();
        window.location.reload();
      }
    });
  };

  // eslint-disable-next-line
  const onAuthStateChange = (authState: string, data: any) => {
    redirectToAppIfSSOLogin();
    redirectToAppIfLandedOnBaseUrl();

    if (
      authState === "signedIn" &&
      localStorage.getItem("amplify-authenticator-authState") === "signedIn" &&
      window.location.pathname.includes("/login/signup") &&
      !data.isSigningIn
    ) {
      signOut();
    }
  };

  return (
    <I18nextProvider i18n={i18nextInstance}>
      <Provider store={store}>
        <ToastProvider>
          <BatchModeContextProvider>
            <Authenticator
              theme={{}}
              hideDefault={true}
              amplifyConfig={amplifyConfig}
              onStateChange={onAuthStateChange}
            >
              <BBSignInView />
              <BBSignUpView />
              <BBConfirmSignInView />
              <BBConfirmSignUpView />
              <BBForgotPasswordView />
              <BBRequireNewPasswordView />

              <Router basename="/login">
                <RoutesContainer appBaseName="login" />
              </Router>
              <Router basename="/insights">
                <RoutesContainer appBaseName="insights" />
              </Router>
              <Router basename="/care">
                <RoutesContainer appBaseName="care" />
              </Router>
              <Router basename="/publish">
                <RoutesContainer appBaseName="publish" />
              </Router>
              <Router basename="/listen">
                <RoutesContainer appBaseName="listen" />
              </Router>
              <Router basename="/managed">
                <RoutesContainer appBaseName="managed" />
              </Router>
              <Router basename="/settings">
                <RoutesContainer appBaseName="settings" />
              </Router>
              <Router basename="/report">
                <RoutesContainer appBaseName="report" />
              </Router>
            </Authenticator>
          </BatchModeContextProvider>
        </ToastProvider>
      </Provider>
    </I18nextProvider>
  );
};

export default App;
