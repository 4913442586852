import { useTranslation } from "react-i18next";
import { FormInstance } from "antd/lib/form";
import React, { useMemo, useState, ChangeEvent } from "react";

import ContextMenu from "./ContextMenu";
import { KeywordSearch } from "./KeywordSearch";
import ExplanationTooltip from "./ExplanationTooltip";
import { Icon, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import { KeywordsTranslation } from "./KeywordsTranslation";
import InputChipQuery from "../../inputChip/InputChipQuery";

import s from "./Keywords.module.less";

import { flattenKeywords, InputChipQueryLabelsEnum } from "../../inputChip/InputChipQueryConfig";

import { QuestionCircleIcon } from "@assets/index";

export interface IKeywordsDropdownProps {
  name: string;
  form: FormInstance;
  showTranslationSwitch?: boolean;
  onChangeInputValue?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const DEFAULT_MAX_CHARACTER_COUNT = 35;

export const KeywordsDropdown = ({
  name,
  form,
  showTranslationSwitch,
  onChangeInputValue,
}: IKeywordsDropdownProps) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");

  const flattenedVal = useMemo(
    () => flattenKeywords(form.getFieldValue(name) || []),
    // eslint-disable-next-line
    [form.getFieldValue(name)],
  );

  const onChipAdd = (chip: string) => {
    const val = flattenedVal;

    val.push(chip);
    form.setFieldsValue({ [name]: val });
  };

  const onChipRemove = (__: string, idx: number) => {
    const newValue = [...flattenedVal];
    newValue.splice(idx, 1);
    const nextChip: keyof typeof InputChipQueryLabelsEnum = newValue[
      idx
    ] as keyof typeof InputChipQueryLabelsEnum;

    if (!!InputChipQueryLabelsEnum[nextChip]) {
      newValue.splice(idx, 1);
    }

    const lastChip: keyof typeof InputChipQueryLabelsEnum = newValue[
      newValue.length - 1
    ] as keyof typeof InputChipQueryLabelsEnum;

    if (!!InputChipQueryLabelsEnum[lastChip]) {
      newValue.splice(newValue.length - 1, 1);
    }

    form.setFieldsValue({ [name]: newValue });
  };

  const filteredKeywords = useMemo(() => {
    if (!searchTerm) {
      return flattenedVal;
    }

    return flattenedVal.filter(keyword => keyword.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [flattenedVal, searchTerm]);

  const onClearAll = () => {
    form.setFieldsValue({ [name]: [] });
  };

  return (
    <div id="keywords-dropdown" className={s.bbKeywordsDropdown} data-testid="keywords-dropdown">
      <KeywordSearch
        name={name}
        form={form}
        setSearchValue={setSearchTerm}
        chipCount={flattenedVal.length}
      />
      <span className={s.bbKeywordsDropdownExplanation}>
        {t(`components:filters:keywords:explanation`)}
        <Tooltip title={<ExplanationTooltip />} zIndex={10000} overlayStyle={{ minWidth: 520 }}>
          <Icon
            _size="sm"
            icon={QuestionCircleIcon}
            className={s.bbKeywordsDropdownExplanationIcon}
          />
        </Tooltip>
      </span>
      <InputChipQuery
        autoFocus
        _size="sm"
        value={filteredKeywords}
        onChipAdd={onChipAdd}
        onChange={onChangeInputValue}
        ContextMenu={ContextMenu}
        onChipRemove={onChipRemove}
        id="keywords-dropdown-input"
        disabled={!!searchTerm.length}
        onClearAll={onClearAll}
        wrapperClassName={s.bbKeywordsDropdownWrapper}
        placeholder={
          form.getFieldValue(name)?.length ? "" : t(`components:filters:keywords:sub_placeholder`)
        }
      />

      {showTranslationSwitch && <KeywordsTranslation name={name} />}
    </div>
  );
};
