import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import AgentPerformanceWidgets from "./AgentPerformanceWidgets";

import { meSelector } from "@store/me/selectors";
import { filterDataSelector } from "@store/filters/selectors";

import { engageConfig, managedAgentsConfig } from "./AgentPerformance.config";
import { useDashboardData } from "@utils/useDashboardData";

import { OverviewComponentType } from "@store/dashboard/types";

export const AgentPerformance: FC = () => {
  const me = useSelector(meSelector);
  const filters = useSelector(filterDataSelector);

  const components = useMemo<OverviewComponentType[]>(() => ["explorer_agent_performance"], []);

  const { data, isFetching } = useDashboardData(components);

  const config = me?.client?.is_care_managed ? managedAgentsConfig : engageConfig;

  return data ? (
    <AgentPerformanceWidgets
      loading={isFetching}
      data={data?.explorer_agent_performance}
      config={config}
      filters={filters || {}}
    />
  ) : null;
};
