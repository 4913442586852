import { i18n } from "i18next";
import { useEffect } from "react";
import { ActionType } from "typesafe-actions";
import { useDispatch, useSelector } from "react-redux";

import {
  triggerNextCommentBatch,
  performCommentActionSuccessAction,
} from "@store/comments/actions";
import { getReplyCommentsSelector } from "@store/replies/selectors";
import { commentsSelector } from "@store/comments/selectors";

import {
  IComment,
  ICommentReply,
  ICommentsState,
  ReplyPlatformType,
  ICommentActionPayload,
  CommentListType,
  IComments,
  commentsListsArray,
  DEFAULT_COMMENT_LIST_KEY,
} from "@store/comments/types";
import { RootState } from "@store/index";
import { getI18next } from "../languages/i18next";
import { PlatformType } from "@store/platform/types";
import { IRepliesState, IReplyData } from "@store/replies/types";
import { PostPlacementEnum } from "@components/_common/Social/Post/Post.type";

const i18nextInstance = getI18next() as i18n;

export const getDefaultSortOptions = () => [
  {
    id: "desc",
    value: "desc",
    label: i18nextInstance.t("components:comments:sort:newest"),
  },
  {
    id: "asc",
    value: "asc",
    label: i18nextInstance.t("components:comments:sort:oldest"),
  },
];

export const getAiInboxSortOptions = () => [
  {
    id: "desc|ai_inbox_score",
    label: i18nextInstance.t("components:inbox:sort:higherIntent"),
  },
  {
    id: "asc|ai_inbox_score",
    label: i18nextInstance.t("components:inbox:sort:lowerIntent"),
  },
  {
    id: "desc|created_time",
    label: i18nextInstance.t("components:inbox:sort:newest"),
  },
  {
    id: "asc|created_time",
    label: i18nextInstance.t("components:inbox:sort:oldest"),
  },
];

export const getDefaultReplyPlatformTypeOptions = (): {
  value: ReplyPlatformType;
  label: string;
  id: ReplyPlatformType;
}[] => [
  {
    id: "all",
    value: "all",
    label: i18nextInstance.t("components:comments:filterByReplyPlatformType:all"),
  },
  {
    id: "brandbastion",
    value: "brandbastion",
    label: i18nextInstance.t("components:comments:filterByReplyPlatformType:brandbastion"),
  },
  {
    id: "platform",
    value: "platform",
    label: i18nextInstance.t("components:comments:filterByReplyPlatformType:platform"),
  },
];

/**
 * TODO: Not really ideal. We should look to adjust our contracts, so that comment actions
 * match inboxStats and comment.reply props.
 */
export enum ActionToReplyProp {
  like = "is_liked",
  hide = "is_hidden",
  flag = "is_flagged",
  ignore = "is_ignored",
  review = "is_reviewed",
  delete = "is_deleted",
  star = "is_starred",
  dismiss_errors = "is_error_dismissed",
}

export const canReplyToComment = (comment: IComment, isHidden: boolean) => {
  if (isHidden) {
    if (
      comment.platform_type.id === "facebook" ||
      comment.platform_type.id === "twitter" ||
      (comment.platform_type.id === "tiktok" && !comment.has_ads)
    ) {
      return true;
    }
    return false;
  }

  return true;
};

export const extractCommentLists = (
  state: ICommentsState | IRepliesState,
): Partial<Record<CommentListType, IComments>> => {
  const result: Partial<Record<CommentListType, IComments>> = {};

  commentsListsArray.forEach(key => {
    if (key in state) {
      result[key] = state[key] as IComments;
    }
  });

  return result;
};

// TODO: this will be reworked later
// To find which comment section we're on
export const findCommentListKey = (listId: CommentListType = DEFAULT_COMMENT_LIST_KEY) => {
  const searchParams = new URLSearchParams(window.location.search);
  const conversationListId = searchParams.get("section") as CommentListType;
  return conversationListId === "listen-conversation_list" ? conversationListId : listId;
};

// Sets comment `reply` props
export function getNewReplyState(
  currentState: ICommentReply,
  commentActionPayload: ICommentActionPayload,
) {
  let state: ICommentReply = {
    ...currentState,
    [ActionToReplyProp[commentActionPayload.action]]: commentActionPayload.value,
  };

  if (commentActionPayload.action === "ignore") {
    state = {
      ...state,
      is_starred: false,
    };
  }

  return state;
}

export function handleSuccessfulCommentActionState<S extends ICommentsState | IRepliesState>(
  state: S,
  action: ActionType<typeof performCommentActionSuccessAction>,
): S {
  const listKey = findCommentListKey(action.payload.commentAction.listId);
  const commentIdx = state[listKey].comments.findIndex(
    comment => comment.id === action.payload.commentAction.id,
  );

  if (state[listKey].comments[commentIdx]) {
    if (action.payload.commentAction.action.includes("hide")) {
      return {
        ...state,
        [listKey]: {
          ...state[listKey],
          comments: Object.assign([], {
            ...state[listKey].comments,
            [commentIdx]: {
              ...state[listKey].comments[commentIdx],
              actionsProcessing: state.actionsProcessing.filter(
                actionProcessing => actionProcessing !== action.payload.commentAction.action,
              ),
              actions: {
                ...state[listKey].comments[commentIdx].actions,
                can_reply: canReplyToComment(
                  state[listKey].comments[commentIdx],
                  action.payload.commentAction.value,
                ),
              },
              moderation: {
                ...state[listKey].comments[commentIdx].moderation,
                is_reviewed: action.payload.commentAction.value
                  ? action.payload.commentAction.value
                  : state[listKey].comments[commentIdx].moderation.is_reviewed,
              },
              is_hidden: action.payload.commentAction.value,
            },
          }),
        },
      };
    }

    if (action.payload.commentAction.action.includes("delete")) {
      return {
        ...state,
        [listKey]: {
          ...state[listKey],
          comments: Object.assign([], {
            ...state[listKey].comments,
            [commentIdx]: {
              ...state[listKey].comments[commentIdx],
              actionsProcessing: state.actionsProcessing.filter(
                processingAction => processingAction !== action.payload.commentAction.action,
              ),
              moderation: {
                ...state[listKey].comments[commentIdx].moderation,
                is_reviewed: action.payload.commentAction.value,
              },
              is_deleted: action.payload.commentAction.value,
            },
          }),
        },
      };
    }

    if (action.payload.commentAction.action.includes("like")) {
      return {
        ...state,
        [listKey]: {
          ...state[listKey],
          comments: Object.assign([], {
            ...state[listKey].comments,
            [commentIdx]: {
              ...state[listKey].comments[commentIdx],
              actionsProcessing: state.actionsProcessing.filter(
                actionProcessing => actionProcessing !== action.payload.commentAction.action,
              ),
              is_liked: action.payload.commentAction.value,
            },
          }),
        },
      };
    }

    if (action.payload.commentAction.action.match(/review|flag/)) {
      return {
        ...state,
        [listKey]: {
          ...state[listKey],
          comments: Object.assign([], {
            ...state[listKey].comments,
            [commentIdx]: {
              ...state[listKey].comments[commentIdx],
              actionsProcessing: state.actionsProcessing.filter(
                actionProcessing => actionProcessing !== action.payload.commentAction.action,
              ),
              moderation: {
                ...state[listKey].comments[commentIdx].moderation,
                [ActionToReplyProp[action.payload.commentAction.action]]:
                  action.payload.commentAction.value,
              },
            },
          }),
        },
      };
    }

    if (action.payload.commentAction.action.includes("dismiss_errors")) {
      const commentsStateCopy = Object.assign([], state[listKey].comments);
      commentsStateCopy.splice(commentIdx, 1);

      return {
        ...state,
        [listKey]: {
          ...state[listKey],
          comments: commentsStateCopy,
        },
      };
    }

    // Flag/ignore actions are handled in `reply` prop of comment
    return {
      ...state,
      [listKey]: {
        ...state[listKey],
        comments: Object.assign([], {
          ...state[listKey].comments,
          [commentIdx]: {
            ...state[listKey].comments[commentIdx],
            actionsProcessing: state.actionsProcessing.filter(
              actionProcessing => actionProcessing !== action.payload.commentAction.action,
            ),
            reply: getNewReplyState(
              state[listKey].comments[commentIdx].reply,
              action.payload.commentAction,
            ),
          },
        }),
      },
    };
  }

  return state;
}

export function handleFailedCommentActionState<S extends ICommentsState | IRepliesState>(state: S) {
  const listKey = findCommentListKey();

  return {
    ...state,
    [listKey]: {
      ...state[listKey],
      comments: Object.assign([], {
        ...state[listKey].comments.map(comment => {
          comment.actionsProcessing = [];
          return comment;
        }),
      }),
    },
  };
}

export const gethideUnhideTooltip = (
  isHidden: boolean,
  platformType: PlatformType,
  hideUnhideTooltip?: string,
) => {
  if (!!hideUnhideTooltip) {
    return hideUnhideTooltip;
  }

  if (isHidden) {
    return i18nextInstance.t(`components:comments:tooltips:unhide:${platformType}`);
  }

  return i18nextInstance.t("components:comments:tooltips:hide");
};

export const isPrivateConversation = (post_placement: PostPlacementEnum) =>
  post_placement === PostPlacementEnum.STORY || post_placement === PostPlacementEnum.DM;

export const isStoryAttachment = (description: string) =>
  description === "story_reply" || description === "story_mention";

const findCommentById = (comments: IComment[], replyingCommentId: string) =>
  comments?.find(({ id }) => id === replyingCommentId);

export const useReplyingComment = (replyData: IReplyData, listId?: CommentListType) => {
  const dispatch = useDispatch();
  const conversationListId = findCommentListKey(listId);
  const comments = useSelector((state: RootState) => commentsSelector(state, conversationListId));
  const replyComments = useSelector((state: RootState) =>
    getReplyCommentsSelector(state, conversationListId),
  );

  const commentId = replyData.replying_comment_id;

  const replyingComment =
    findCommentById(comments, commentId) || findCommentById(replyComments, commentId);

  useEffect(() => {
    if (!replyingComment) {
      dispatch(triggerNextCommentBatch(commentId));
    }
  }, [replyingComment, commentId, dispatch]);

  return replyingComment;
};
