import React, { ReactNode } from "react";

import {
  CommentIcon,
  GridIcon,
  HashIcon,
  HeadphoneIcon,
  HeartIcon,
  StarIcon,
  RepeatIcon,
  PostIcon,
  ListIcon,
} from "@assets/index";
import { UserGroupIcon } from "@bbdevcrew/bb_ui_kit_fe";

export type ModuleIconMapType = {
  [key: string]: FeatureModuleIconMapType;
};

export type FeatureModuleIconMapType = {
  [key: string]: ReactNode;
};

export const AnalyzeModuleIconMap: FeatureModuleIconMapType = {
  topics: <ListIcon />,
  "listen-overview": <GridIcon />,
  "listen-sentiment_details": <HeartIcon />,
  "listen-reviews": <StarIcon />,
  "listen-agent_performance": <UserGroupIcon />,
  "listen-conversation_list": <CommentIcon />,
  "listen_more-trending_hashtags": <HashIcon />,
  "listen_more-listening_sources": <HeadphoneIcon />,
  "report-posts": <PostIcon />,
  "report-compare": <RepeatIcon />,
};

export const ModuleIconMap: ModuleIconMapType = {
  listen: AnalyzeModuleIconMap,
  insights: AnalyzeModuleIconMap,
};
