import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useState, useEffect, useMemo, useCallback } from "react";
import classNames from "classnames";

import { Tooltip } from "antd";
import Timeline from "@components/_common/TimelineChart";

import Aggregations from "@components/insights/search/aggregations/Aggregations";
import SentimentChart from "@components/insights/search/sentimentChart/SentimentChart";
import CommentBreakdown from "@components/insights/search/commentBreakdown/CommentBreakdown";

import DownloadCSV from "@components/_common/DownloadCSV";
import { Button, InputWhite, SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";
import { CommentsGridContainer } from "@containers/Comments/Comments";
import CommentsDownloadInfo from "@components/insights/search/commentsDownloadInfo/CommentsDownloadInfo"; // eslint-disable-line max-len
import Header from "../header";
import DownloadButton from "../downloadButton";

import { filterDataSelector } from "@store/filters/selectors";
import { postCommentsSearchAction } from "@store/comments/actions";

import {
  sentimentColorData,
  normalizeSentimentData,
  getLines,
  getCSVData,
  getCSVTableHeaders,
  DEFAULT_COMMENT_EXPORT_LIMIT,
  useDownloadCommentsCSV,
  normalizeTimelineData,
} from "./SentimentDetails.helpers";

import { useAppFilters } from "@utils/appFilters";
import { useYTSelected } from "@utils/useYTselected";
import { getDefaultSortOptions } from "@utils/comments";
import { useTiktokEnabled } from "@utils/useTiktokEnabled";

import s from "./SentimentDetails.module.less";

import { DownloadIcon } from "@assets/index";

import { IGetInboxRepliesPayload, IPostCommentBody } from "@store/comments/types";
import { IDashboardPayload } from "@store/dashboard/types";
import { IFilters } from "@store/filters/types";
import { IPieChartUnit, IAreaChartData } from "./SentimentDetails.type";
import {
  getDashboardSelectorPending,
  getDashboardSelectorSuccessful,
  getSentimentBreakdown,
  getSentimentTimeline,
} from "@store/dashboard/selectors";
import { meSuccessfulSelector } from "@store/me/selectors";
import { postDashboardAction } from "@store/dashboard/actions";

export const SentimentDetails: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateSearchDataAndUrl } = useAppFilters();
  const isTiktokEnabled = useTiktokEnabled();

  const [sortValue, setSortValue] = useState<string>(getDefaultSortOptions()[0].value);
  const [commentSentimentData, setCommentSentimentData] = useState<IPieChartUnit[]>([]);
  const [commentTimelineData, setCommentTimelineData] = useState<IAreaChartData[]>([]);
  const [commentSentimentTotal, setTotal] = useState<IPieChartUnit>({} as IPieChartUnit);
  const [topSectionsLoading, setTopSectionsLoading] = useState(false);
  const [topSectionsLoaded, setTopSectionsLoaded] = useState(false);
  const [bottomSectionsLoading, setBottomSectionsLoading] = useState(false);

  const filters = useSelector(filterDataSelector);
  const sentimentTimelineData = useSelector(getSentimentTimeline);
  const sentimentBreakdownData = useSelector(getSentimentBreakdown);
  const meFetched = useSelector(meSuccessfulSelector);
  const dashboardLoading = useSelector(getDashboardSelectorPending);
  const dashboardLoaded = useSelector(getDashboardSelectorSuccessful);

  const {
    triggerCommentsDownload,
    commentsDownloadModalOpen,
    closeModal,
    commentsCSVPending,
    commentsCSVFetched,
  } = useDownloadCommentsCSV(filters || {}, sortValue);
  const isYTFilterSelected = useYTSelected(filters?.platform_types || []);

  const getDashboardData = useCallback(
    (data: IDashboardPayload) => dispatch(postDashboardAction(data)),
    [dispatch],
  );

  const getTopSections = useCallback(() => {
    getDashboardData({
      filters,
      components: ["sentiment_timeline"],
    });
  }, [filters, getDashboardData]);

  const getBottomSections = useCallback(() => {
    getDashboardData({
      filters,
      components: ["sentiment_breakdown"],
    });
  }, [filters, getDashboardData]);

  useEffect(() => {
    if (meFetched && filters && !topSectionsLoading) {
      getTopSections();
      setTopSectionsLoading(true);
    }
    // eslint-disable-next-line
  }, [meFetched, filters, getTopSections]);

  useEffect(() => {
    if (!topSectionsLoading && topSectionsLoaded && !bottomSectionsLoading) {
      getBottomSections();
      setBottomSectionsLoading(true);
    }
    // eslint-disable-next-line
  }, [topSectionsLoading, topSectionsLoaded]);

  useEffect(() => {
    if (dashboardLoaded && !dashboardLoading) {
      if (topSectionsLoading) {
        setTopSectionsLoading(false);
        setTopSectionsLoaded(true);
      }
      if (bottomSectionsLoading) {
        setBottomSectionsLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [dashboardLoading, dashboardLoaded]);

  useEffect(() => {
    if (!topSectionsLoading && sentimentTimelineData) {
      const commentStatData = normalizeSentimentData(sentimentTimelineData);
      const pieChartTotal = commentStatData.find(unit => unit.rawName === "total");
      const pieChartData = commentStatData.filter(unit => unit.rawName !== "total");

      if (pieChartTotal) {
        setTotal(pieChartTotal);
        setCommentSentimentData(pieChartData);
      }
      if (sentimentTimelineData) {
        const timelineData = normalizeTimelineData(sentimentTimelineData);
        setCommentTimelineData(timelineData);
      }
    }
  }, [topSectionsLoading, sentimentTimelineData]);

  const fetchConversationList = useCallback((body: IPostCommentBody | IGetInboxRepliesPayload) => {
    const sortVals = body.sort?.split("|");
    return postCommentsSearchAction({
      ...body,
      sort: sortVals && sortVals[0],
      sort_field: sortVals && sortVals[1],
    });
  }, []);

  const filterToSpecificItem = useCallback(
    (item: IFilters) => {
      const newDataStats = {
        ...filters,
        ...item,
      };
      updateSearchDataAndUrl(newDataStats, "id");
    },
    [filters, updateSearchDataAndUrl],
  );

  const onFilterAuthorId = useCallback(
    (authorIds: string[]) => {
      updateSearchDataAndUrl({ author_ids: authorIds }, "id");
    },
    [updateSearchDataAndUrl],
  );

  const lines = useMemo(() => {
    return isTiktokEnabled
      ? getLines()
      : getLines().filter(line => line.dataKey !== "not_applicable");
  }, [isTiktokEnabled]);

  return (
    <>
      <Header>
        <DownloadButton />
      </Header>
      <div className={s.bbSentimentDetails}>
        <div className={s.bbSentimentDetailsRow}>
          <div className={s.bbSentimentDetailsChartPie}>
            <SentimentChart
              isLoading={topSectionsLoading}
              data={commentSentimentData}
              total={commentSentimentTotal}
              filterToSpecificItem={filterToSpecificItem}
              className={classNames(s.bbSentimentDetailsChart)}
            />
          </div>
          <Timeline
            filters={filters || {}}
            data={commentTimelineData}
            filterToSpecificItem={filterToSpecificItem}
            lines={lines}
            className={s.bbSentimentDetailsChart}
            downloadCSVButton={
              <DownloadCSV
                icon={<DownloadIcon />}
                data={getCSVData(commentTimelineData)}
                fileNamePrefix="timeline"
                disabled={isYTFilterSelected}
                tableHeaders={getCSVTableHeaders()}
              />
            }
          />
        </div>
        <div className={s.bbSentimentDetailsRow}>
          <div className={s.bbSentimentDetailsChartBreakdown}>
            <Aggregations
              isLoading={topSectionsLoading}
              data={sentimentTimelineData || null}
              widthInfo={156}
              filters={filters || {}}
              colors={sentimentColorData}
              className={s.bbSentimentDetailsChart}
            />
          </div>
          <CommentBreakdown
            className={classNames(s.bbSentimentDetailsChart, s.bbSentimentDetailsChartPlatforms)}
            appFilters={filters || {}}
            filterToSpecificItem={filterToSpecificItem}
            data={sentimentBreakdownData?.items || []}
          />
        </div>
        <div
          className={s.bbSentimentDetailsComments}
          data-cy="comments-grid-wrapper"
          id="col-comments"
          data-sonly-target="intelligence-search__recent-comments"
        >
          <div className={s.bbSentimentDetailsCommentsHeader}>
            <h5 data-cy="comments-title" className={s.bbSentimentDetailsCommentsTitle}>
              {t("components:comments:title")}
            </h5>
            <div className={s.bbSentimentDetailsCommentsRight}>
              <SelectStyled
                _size="sm"
                value={sortValue}
                onChange={(value: string) => setSortValue(value)}
                InputVariation={InputWhite}
                options={getDefaultSortOptions()}
                wrapperClassName="shadow-sm-2"
              />
              <Tooltip
                placement="top"
                title={
                  isYTFilterSelected
                    ? t("pages:insights:ytNoDownload")
                    : t("components:comments:download:downloadTooltip", {
                        limit: DEFAULT_COMMENT_EXPORT_LIMIT,
                      })
                }
              >
                <span>
                  <Button
                    disabled={isYTFilterSelected}
                    className={s.bbDownloadCommentsCSV}
                    onClick={() => !isYTFilterSelected && triggerCommentsDownload()}
                  >
                    <DownloadIcon />
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
          <CommentsGridContainer
            showHideAction
            showDeleteAction
            showLikeAction
            showDetailsAction
            showLinkedInReactionAction
            filters={filters}
            sortValue={sortValue}
            providedFetchAction={fetchConversationList}
            providedFilterAuthorIdAction={(authorIds: string[]) => onFilterAuthorId(authorIds)}
            className={s.bbSentimentDetailsCommentsGrid}
          />
        </div>
        <CommentsDownloadInfo
          loading={commentsCSVPending}
          open={commentsDownloadModalOpen}
          commentsDownloaded={commentsCSVFetched}
          downloadAgain={triggerCommentsDownload}
          closeModal={closeModal}
        />
      </div>
    </>
  );
};
