import React from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Topics from "../topics";
import Reviews from "../reviews";
import Sources from "../sources";
import Overview from "../overview";
import SentimentDetails from "../sentimentDetails";
import ConversationList from "../conversationList";
import AgentPerformance from "../agentPerformance";
import Posts from "@components/insights/posts/Posts";
import TiktokTrendingHashtags from "../trendingHashtags";
import Compare from "@components/insights/compare/Compare";

import { getAllModulesSectionsSelector } from "@store/modules/selectors";

import s from "./Listen.module.less";

import { IAvailableSectionsType, IModuleFeatureType } from "@store/modules/types";
import { getCurrentModuleName } from "@utils/paths";

export const Listen: React.FC = () => {
  const [searchParams] = useSearchParams();
  const conversationListId = searchParams.get("section") as IModuleFeatureType;

  const moduleId = getCurrentModuleName();

  const modulesSections = useSelector(getAllModulesSectionsSelector);

  const sections = modulesSections[moduleId as IAvailableSectionsType];
  const nonAvailableFeatures = sections
    ?.flatMap(section => section.features)
    .filter(feature => !feature.is_visible || feature.is_locked)
    .map(feature => feature.id);

  const isFeatureAvailable = (featureName: IModuleFeatureType) =>
    !nonAvailableFeatures?.includes(featureName);

  return (
    <div className={s.bbListenContainer}>
      {isFeatureAvailable("listen-overview") && conversationListId === "listen-overview" && (
        <Overview />
      )}
      {isFeatureAvailable("listen-sentiment_details") &&
        conversationListId === "listen-sentiment_details" && <SentimentDetails />}
      {isFeatureAvailable("topics") && conversationListId === "topics" && <Topics />}
      {isFeatureAvailable("listen-reviews") && conversationListId === "listen-reviews" && (
        <Reviews />
      )}
      {isFeatureAvailable("listen-agent_performance") &&
        conversationListId === "listen-agent_performance" && <AgentPerformance />}
      {isFeatureAvailable("listen-conversation_list") &&
        conversationListId === "listen-conversation_list" && <ConversationList />}
      {isFeatureAvailable("listen_more-trending_hashtags") &&
        conversationListId === "listen_more-trending_hashtags" && <TiktokTrendingHashtags />}
      {isFeatureAvailable("listen_more-listening_sources") &&
        conversationListId === "listen_more-listening_sources" && <Sources />}
      {isFeatureAvailable("report-posts") && conversationListId === "report-posts" && <Posts />}
      {isFeatureAvailable("report-compare") && conversationListId === "report-compare" && (
        <Compare />
      )}
    </div>
  );
};
