import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "../";
import {
  getCustomTagsSuccessAction,
  getCustomTagsFailureAction,
  postCustomTagSuccessAction,
  postCustomTagFailureAction,
  deleteCustomTagSuccessAction,
  deleteCustomTagFailureAction,
  deleteCustomTagFromCommentSuccessAction,
  deleteCustomTagFromCommentFailureAction,
} from "./actions";

import {
  GET_CUSTOM_TAGS,
  POST_CUSTOM_TAG,
  DELETE_CUSTOM_TAG,
  DELETE_CUSTOM_TAG_FROM_COMMENT,
} from "./actionTypes";

import { customTags } from "@utils/paths";

import { CustomTagsActions } from "./types";
import { handleError } from "@utils/apiErrorHandler";
import { getHeaders } from "@utils/headers";
import { ICustomTag } from "@components/_common/TagsDropdown/TagsDropdown.type";

export const getCustomTags = (
  action$: Observable<CustomTagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_CUSTOM_TAGS)),
    switchMap(() => {
      return ajax
        .get<{ items: ICustomTag[] }>(
          customTags,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getCustomTagsSuccessAction(data)),
          catchError(e => handleError(e, getCustomTagsFailureAction)),
        );
    }),
  );

export const postCustomTag = (
  action$: Observable<CustomTagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_CUSTOM_TAG)),
    switchMap(a => {
      return ajax
        .post<ICustomTag>(
          customTags,
          {
            comment_id: a.payload.comment_id,
            label: a.payload.label,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(data => [
            postCustomTagSuccessAction(
              data,
              a.payload.comment_id || "",
              a.payload.parent_comment_id,
              a.payload.listId,
            ),
          ]),
          catchError(e => {
            return [
              postCustomTagFailureAction(a.payload.comment_id),
              handleError(e, postCustomTagFailureAction),
            ];
          }),
        );
    }),
  );

export const deleteCustomTagFromDropdown = (
  action$: Observable<CustomTagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_CUSTOM_TAG)),
    switchMap(a => {
      return ajax
        .delete<ICustomTag>(
          `${customTags}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteCustomTagSuccessAction(a.payload)),
          catchError(e => handleError(e, deleteCustomTagFailureAction)),
        );
    }),
  );

export const deleteCustomTagFromComment = (
  action$: Observable<CustomTagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_CUSTOM_TAG_FROM_COMMENT)),
    switchMap(a => {
      return ajax
        .delete(
          `${customTags}/${a.payload.id}/comments/${a.payload.comment_id}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteCustomTagFromCommentSuccessAction(a.payload)),
          catchError(e => handleError(e, deleteCustomTagFromCommentFailureAction)),
        );
    }),
  );
