import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { SourceModal } from "./SourceModal";
import SourcesTableRow from "./SourcesTableRow";
import { addToast, Button, Modal, SortableTable } from "@bbdevcrew/bb_ui_kit_fe";
import Breadcrumbs from "../breadcrumbs";

import {
  createSourceAction,
  deleteSourceAction,
  getSourcesListAction,
  updateSourceAction,
} from "@store/sources/actions";
import {
  createdSourceSelector,
  creatingSourceFailedMessageSelector,
  creatingSourceFailedSelector,
  creatingSourceSelector,
  deletedSourceSelector,
  deletingSourceFailedMessageSelector,
  deletingSourceFailedSelector,
  deletingSourceSelector,
  getSourcesListPendingSelector,
  getSourcesListSelector,
  updatedSourceSelector,
  updatingSourceFailedMessageSelector,
  updatingSourceFailedSelector,
  updatingSourceSelector,
} from "@store/sources/selectors";

import s from "./Sources.module.less";

import { getTableColumns, TABLE_PAGE_SIZE } from "./Sources.helpers";
import { ICreateSourcePayload, Source } from "@store/sources/types";

import { PlusIcon } from "@assets/index";

export const Sources = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(1);
  const [editingSource, setEditingSource] = useState<Source | undefined>(undefined);
  const [sourceToDelete, setSourceToDelete] = useState<Source | undefined>(undefined);
  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const listenSources = useSelector(getSourcesListSelector);
  const fetchingListenSources = useSelector(getSourcesListPendingSelector);
  const creatingSource = useSelector(creatingSourceSelector);
  const createdSource = useSelector(createdSourceSelector);
  const creatingSourceFailed = useSelector(creatingSourceFailedSelector);
  const creatingSourceFailedMessage = useSelector(creatingSourceFailedMessageSelector);
  const updatingSource = useSelector(updatingSourceSelector);
  const updatedSource = useSelector(updatedSourceSelector);
  const updatingSourceFailed = useSelector(updatingSourceFailedSelector);
  const updatingSourceFailedMessage = useSelector(updatingSourceFailedMessageSelector);
  const deletingSource = useSelector(deletingSourceSelector);
  const deletedSource = useSelector(deletedSourceSelector);
  const deletingSourceFailed = useSelector(deletingSourceFailedSelector);
  const deletingSourceFailedMessage = useSelector(deletingSourceFailedMessageSelector);

  const finishedAction = createdSource || updatedSource || deletedSource;

  const getData = useMemo(() => {
    const startIndex = (page - 1) * TABLE_PAGE_SIZE;
    const endIndex = startIndex + TABLE_PAGE_SIZE;

    return listenSources.slice(startIndex, endIndex);
  }, [page, listenSources]);

  const getListenSources = useCallback(() => dispatch(getSourcesListAction()), [dispatch]);

  useEffect(() => {
    getListenSources();
  }, [finishedAction, getListenSources]);

  useEffect(() => {
    if (finishedAction) {
      addToast({
        type: "success_accent",
        title: t("components:listen:sources:toastMessages:success"),
      });
    }
  }, [finishedAction, t]);

  useEffect(() => {
    const failed = creatingSourceFailed || updatingSourceFailed || deletingSourceFailed;
    const message =
      creatingSourceFailedMessage || updatingSourceFailedMessage || deletingSourceFailedMessage;

    if (failed && message) addToast({ type: "danger_accent", title: message });
  }, [
    creatingSourceFailed,
    updatingSourceFailed,
    deletingSourceFailed,
    creatingSourceFailedMessage,
    updatingSourceFailedMessage,
    deletingSourceFailedMessage,
  ]);

  const onTablePageChange = (value: number) => setPage(value);

  const editSource = (source: Source) => {
    setEditingSource(source);
    setIsSourceModalOpen(true);
  };

  const onDeleteSource = (source: Source) => {
    setSourceToDelete(source);
    setIsDeleteModalOpen(true);
  };

  const onSubmitSourceForm = (data: Source | ICreateSourcePayload) => {
    dispatch(
      !!editingSource
        ? updateSourceAction({ ...(data as Source), id: editingSource.id })
        : createSourceAction({ ...data, type: "hashtag" }),
    );
    setIsSourceModalOpen(false);
    setEditingSource(undefined);
  };

  const deleteSource = () => {
    dispatch(deleteSourceAction(sourceToDelete?.id as string));
    setIsDeleteModalOpen(false);
    setSourceToDelete(undefined);
  };

  const onCloseSourceModal = () => {
    setIsSourceModalOpen(false);
    setEditingSource(undefined);
  };

  const onCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSourceToDelete(undefined);
  };

  return (
    <>
      <Breadcrumbs />
      <div className={s.bbSources}>
        <div className={s.bbSourcesHeader}>
          <Button _size="sm" _type="primary" onClick={() => setIsSourceModalOpen(true)}>
            <span className={s.bbAddSourceBtnIcon}>
              <PlusIcon />
            </span>
            <span>{t("components:listen:sources:addSource")}</span>
          </Button>
        </div>

        <SortableTable
          data={getData}
          loading={fetchingListenSources}
          tableRow={SourcesTableRow({ editSource, deleteSource: onDeleteSource })}
          columns={getTableColumns()}
          pagination={{
            total: listenSources.length,
            pageSize: TABLE_PAGE_SIZE,
            currentPage: page,
            onChangePage: onTablePageChange,
          }}
          emptyTableText={t("generic:emptyTable")}
          pageOutOfText={[
            t("components:sortableTable:pageOutOfPart1"),
            t("components:sortableTable:pageOutOfPart1"),
          ]}
        />

        <SourceModal
          isOpen={isSourceModalOpen}
          isSubmitting={creatingSource || updatingSource}
          editingSource={editingSource}
          onClose={onCloseSourceModal}
          onSubmit={onSubmitSourceForm}
        />

        <Modal
          centered
          responsive
          onCancel={onCloseDeleteModal}
          onOk={deleteSource}
          okButtonProps={{ _type: "danger" }}
          cancelLabel={t("generic:cancel")}
          confirmLabel={t("components:listen:sources:deleteConfirmationModal:confirmText")}
          open={isDeleteModalOpen}
          title={t("components:listen:sources:deleteConfirmationModal:title")}
          confirmLoading={deletingSource}
        >
          <p>{t("components:listen:sources:deleteConfirmationModal:text")}</p>
        </Modal>
      </div>
    </>
  );
};
