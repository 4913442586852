import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Reply from "../Reply/Reply";
import { BrandReply } from "./BrandReply";
import MessageCard from "../MessageCard/MessageCard";
import MessageActionsFooter from "../MessageCard/MessageActionsFooter";
import { Button, AlertInfo } from "@bbdevcrew/bb_ui_kit_fe";
import DeleteReplyAlert from "../MessageCard/replyDeleteAlert/ReplyDeleteAlert";
import { DeleteCommentConfirmationModal } from "../DeleteCommentConfirmationModal";

import { deletedReplyIdSelector, deletingBrandReplySelector } from "@store/replies/selectors";
import { deleteBrandReplyAction } from "@store/replies/actions";

import s from "./MessageList.module.less";

import { getMessage } from "./MessageList.helpers";
import { IMessageListParentProps } from "./MessageList.type";
import { isFacebookPlatformStrict } from "@utils/platform";
import { useBrandReplyDropdownItems } from "../MessageCard/dropdowns/useBrandReplyDropdownItems";
import { useCommentActionDropdownItems } from "../MessageCard/dropdowns/useCommentActionDropdownItems"; // eslint-disable-line max-len
import { PostPlacementEnum } from "../Social/Post/Post.type";

import { ExportIcon } from "@assets/index";

export const MessageListParent: FC<IMessageListParentProps> = ({
  parent,
  filters,
  comment,
  replyData,
  showReplyBox,
  onInternalReply,
  isReplyingComment,
  isReplyBtnLoading,
  onClose,
  listId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isDeletingBrandReply = useSelector(deletingBrandReplySelector);
  const deletedReplyId = useSelector(deletedReplyIdSelector);

  const {
    id,
    message,
    username,
    sentiment,
    is_hidden,
    owned,
    brand_reply,
    assigned_users,
    created_time_pretty,
    message_translated,
    language_detected,
    action_error,
    comment_phrase,
    private_replied_public_comment_id,
  } = parent;

  const { brandReplyDropdownItems, showDeleteReplyAlert, toggleDeleteReplyAlert } =
    useBrandReplyDropdownItems();

  const { actionDropdownItems, deleteConfirmationModal } = useCommentActionDropdownItems(
    parent,
    filters,
    replyData.reply_user.platform.id,
  );

  const onDeleteReply = (replyId: string) => {
    dispatch(
      deleteBrandReplyAction(
        replyId,
        replyData.parent?.brand_reply?.is_ignored,
        replyData.parent?.brand_reply?.is_ai_highlighted,
      ),
    );
  };

  const actions = owned ? brandReplyDropdownItems : !isReplyingComment ? actionDropdownItems : [];

  const hideActions =
    !!owned &&
    (!!private_replied_public_comment_id || replyData.post_placement === PostPlacementEnum.REVIEW);

  return (
    <>
      <MessageCard
        message={getMessage(message, comment)}
        messageTranslated={language_detected !== "en" ? message_translated : ""}
        author={username}
        isHidden={is_hidden}
        key={`message-${id}`}
        sentiment={sentiment}
        date={created_time_pretty}
        actionError={action_error}
        commentPhrase={comment_phrase}
        hideActions={hideActions}
        size={isReplyingComment ? "lg" : "md"}
        recommendationType={comment?.recommendation_type}
        type={isReplyingComment ? "highlighted" : "default"}
        assigningCommentId={!isReplyingComment ? id : undefined}
        assignedUsers={!isReplyingComment ? assigned_users || [] : []}
        actionDropdownItems={actions}
        hiddenIconTooltipTitle={comment?.tooltips?.hidden}
        commentTooltips={comment?.tooltips}
        footer={
          <>
            {isReplyingComment && (
              <MessageActionsFooter
                comment={comment}
                filters={filters}
                replyData={replyData}
                assigned_users={assigned_users}
                onClose={onClose}
                listId={listId}
              />
            )}
            {owned && showDeleteReplyAlert && (
              <DeleteReplyAlert
                onApprove={() => onDeleteReply(id)}
                onCancel={toggleDeleteReplyAlert}
                isDeleting={isDeletingBrandReply && deletedReplyId === id}
              />
            )}
          </>
        }
      />

      {showReplyBox ? (
        <Reply
          comment={comment}
          onClickReply={onInternalReply}
          replyUser={replyData.reply_user}
          replierInfo={replyData.replier_info}
          isReplyBtnLoading={isReplyBtnLoading}
          maxMessageLength={replyData.max_message_length}
          replyingCommentId={replyData.replying_comment_id}
          suggestedReplyMessage={replyData?.suggested_reply_message}
          disabledMentions={brand_reply?.warning_type === "ig_mention_removed"}
        />
      ) : is_hidden && !isFacebookPlatformStrict(replyData.reply_user.platform.id) ? (
        <span className={s.bbMessageListHidden}>{t("components:reply:hiddenComments")}</span>
      ) : null}

      {!showReplyBox && replyData.reply_capability?.disabled && (
        <AlertInfo
          size="medium"
          className={s.bbReplyInfoAlert}
          message={replyData.reply_capability.tooltip}
          action={
            <Button _size="sm" _type="secondary" className={s.bbPostLinkBtn}>
              <ExportIcon />{" "}
              <span onClick={() => window.open(replyData.post_preview?.permalink, "_blank")}>
                {t("components:comments:post:linkToPost")}
              </span>
            </Button>
          }
        />
      )}

      {!!brand_reply && (
        <BrandReply
          brand_reply={brand_reply}
          actionDropdownItems={brandReplyDropdownItems}
          showDeleteReplyAlert={showDeleteReplyAlert}
          onDeleteReply={() => onDeleteReply(id)}
          onCancelDeleteReply={toggleDeleteReplyAlert}
        />
      )}

      <DeleteCommentConfirmationModal
        isOpen={deleteConfirmationModal.isOpen}
        onCancel={deleteConfirmationModal.onCancel}
        onConfirm={deleteConfirmationModal.onConfirm}
      />
    </>
  );
};
