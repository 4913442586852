import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useState } from "react";

import { Col, Menu } from "antd";
import Ads from "../../../_common/Social/Ads/Ads";
import { Modal, Button, Tooltip, Dropdown, SearchIcon } from "@bbdevcrew/bb_ui_kit_fe";

import { clearPostAdsAction, getPostAdsAction } from "@store/posts/actions";
import { getPostAdsProcessingSelector, postAdsSelector } from "@store/posts/selectors";

import s from "./PostsTable.module.less";

import { AdIcon, PostIcon, MoreActionsIcon } from "@assets/index";

const cx = cn.bind(s);

interface IPostsTableRowActionsProps extends React.HTMLAttributes<HTMLDivElement> {
  postId: string;
  showAds: boolean;
  onOpenPostClick?: () => void;
}

const PostsTableRowActions: FC<IPostsTableRowActionsProps> = ({
  postId,
  showAds,
  onOpenPostClick,
  className,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [adsOpen, setAdsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const postAds = useSelector(postAdsSelector);
  const arePostAdsLoading = useSelector(getPostAdsProcessingSelector);

  const fetchAds = useCallback(id => dispatch(getPostAdsAction(id)), [dispatch]);
  const clearAds = useCallback(() => dispatch(clearPostAdsAction()), [dispatch]);

  const onOpenAdsClick = () => {
    fetchAds(postId);
    setAdsOpen(true);
  };

  const onCloseAds = () => {
    clearAds();
    setAdsOpen(false);
  };

  const onExploreClick = () =>
    navigate(
      {
        search: "?section=listen-sentiment_details",
      },
      {
        state: {
          filters: {
            post_ids: [postId],
          },
        },
      },
    );

  return (
    <>
      <Col className={cx(s.bbColumn, s.bbColumnActions, className)} span={2}>
        <Tooltip title={t("components:posts:table:exploreTooltip")} placement="top">
          <Button
            _type="primary"
            className={cx(s.bbActionBtn, s.bbExploreBtn)}
            onClick={onExploreClick}
          >
            <SearchIcon />
          </Button>
        </Tooltip>
        <Dropdown
          triggerMode="hover"
          open={isDropdownOpen}
          setOpen={setIsDropdownOpen}
          placement="bottomLeft"
          menuClassName={s.bbPostActionsDropdown}
          trigger={
            <Button _type="primary" className={cx(s.bbActionBtn, s.bbMoreBtn)}>
              <MoreActionsIcon />
            </Button>
          }
        >
          <Menu>
            {showAds && (
              <Menu.Item key="ad" icon={<AdIcon />} onClick={onOpenAdsClick}>
                {t("components:comments:actions:showAd")}
              </Menu.Item>
            )}
            <Menu.Item key="post" icon={<PostIcon />} onClick={onOpenPostClick}>
              {t("components:comments:actions:showPost")}
            </Menu.Item>
          </Menu>
        </Dropdown>
      </Col>
      <Modal
        centered
        hideFooter
        width={800}
        title={t("generic:ads")}
        open={adsOpen}
        onCancel={() => onCloseAds()}
      >
        <Ads
          isLoading={arePostAdsLoading}
          ads={postAds}
          texts={{
            adLabel: t("components:comments:ads:table:adLabel"),
            adSetLabel: t("components:comments:ads:table:adSetLabel"),
            campaignLabel: t("components:comments:ads:table:campaignLabel"),
            spinText: t("pages:loading:spinText"),
          }}
        />
      </Modal>
    </>
  );
};

export default PostsTableRowActions;
