import { useSelector } from "react-redux";
import React, { FC, useMemo } from "react";

import { Col, Row } from "antd";
import TopTags from "./topTags/TopTags";
import AnalysisTrends from "@components/insights/overview/analysisTrends/AnalysisTrends";
import MostUsedEmojis from "@components/insights/overview/mostUsedEmojis/MostUsedEmojis";
import Header from "../header";
import DownloadButton from "../downloadButton";

import { filterDataSelector } from "@store/filters/selectors";

import s from "./Topics.module.less";

import { useDashboardData } from "@utils/useDashboardData";
import { OverviewComponentType } from "@store/dashboard/types";

export const Topics: FC = () => {
  const filters = useSelector(filterDataSelector);

  const components = useMemo<OverviewComponentType[]>(
    () => [
      "explorer_overview_top_emojis",
      "explorer_overview_top_keywords",
      "explorer_overview_top_keyword_phrases",
      "top_tags",
    ],
    [],
  );
  const { data, isFetching } = useDashboardData(components);

  return (
    <>
      <Header>
        <DownloadButton />
      </Header>
      {filters ? (
        <Col xs={24} id="topics-section" className={s.bbTopics}>
          <Row gutter={[28, 28]}>
            <Col className={s.bbTopicsCol}>
              <AnalysisTrends
                filters={filters}
                loading={isFetching}
                wordCloudData={data?.explorer_overview_top_keywords?.items || []}
                keywordPhrasesData={data?.explorer_overview_top_keyword_phrases?.items || []}
              />
            </Col>
            <Col className={s.bbTopicsCol}>
              <MostUsedEmojis
                filters={filters}
                loading={isFetching}
                data={data?.explorer_overview_top_emojis?.items || []}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <TopTags data={data?.top_tags?.items || []} loading={isFetching} />
            </Col>
          </Row>
        </Col>
      ) : null}
    </>
  );
};
