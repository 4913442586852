import { action } from "typesafe-actions";
import {
  ADD_LINKEDIN_REACTION,
  ADD_LINKEDIN_REACTION_SUCCESS,
  DELETE_LINKEDIN_REACTION,
  DELETE_LINKEDIN_REACTION_SUCCESS,
  LINKEDIN_REACTION_FAILURE,
} from "./actionTypes";
import { LinkedInReactionType } from "@components/_common/LinkedInReactionButton/types";
import { CommentListType } from "@store/comments/types";

export const addLinkedInReaction = (
  commentId: string,
  reaction: LinkedInReactionType,
  listId?: CommentListType,
) => action(ADD_LINKEDIN_REACTION, { commentId, reaction, listId });
export const addLinkedInReactionSuccess = (
  commentId: string,
  reaction: LinkedInReactionType,
  listId?: CommentListType,
) => action(ADD_LINKEDIN_REACTION_SUCCESS, { commentId, reaction, listId });

export const deleteLinkedInReaction = (commentId: string, listId?: CommentListType) =>
  action(DELETE_LINKEDIN_REACTION, commentId, listId);
export const deleteLinkedInReactionSuccess = (commentId: string, listId?: CommentListType) =>
  action(DELETE_LINKEDIN_REACTION_SUCCESS, commentId, listId);

export const linkedInReactionFailure = (commentId: string) =>
  action(LINKEDIN_REACTION_FAILURE, commentId);
