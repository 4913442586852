export const BASE_URL_API = process.env.BASE_URL_API;
export const HUBSPOT_FORM_API = process.env.HUBSPOT_FORM_API;

export const getCurrentModuleName = () => {
  if (window.location.pathname.startsWith("/login")) return "login";
  if (window.location.pathname.startsWith("/insights")) return "insights";
  if (window.location.pathname.startsWith("/care")) return "care";
  if (window.location.pathname.startsWith("/publish")) return "publish";
  if (window.location.pathname.startsWith("/settings")) return "settings";
  if (window.location.pathname.startsWith("/listen")) return "listen";
  if (window.location.pathname.startsWith("/managed")) return "managed";

  return "insights";
};

const absolutePath = (relativePath: string) => {
  return `${BASE_URL_API}/${relativePath}`;
};

const constructSpecificEndpoint = (moduleName: string, subFeatureName: string) => {
  return `${BASE_URL_API}/${moduleName}/${subFeatureName}`;
};

// Common
export const me = absolutePath("users/me");
export const users = absolutePath("users");
export const posts = absolutePath("posts");
export const trends = absolutePath("trends");
export const tiktok = absolutePath("tiktok");
export const modules = absolutePath("modules");
export const replies = absolutePath("replies");
export const upload = absolutePath("upload/url");
export const postTags = absolutePath("post-tags");
export const customTags = absolutePath("custom-tags");
export const clientTags = absolutePath("client-tags");
export const commentsActions = absolutePath("comments");
export const autocomplete = absolutePath("autocomplete");
export const savedFilters = absolutePath("saved-filters");
export const commentsSearch = absolutePath("comments/search");
export const promptComment = absolutePath("prompts/comments");
export const promptCaptions = absolutePath("prompts/captions");
export const promptTemplates = absolutePath("prompts/templates");
export const assetsOnboarding = absolutePath("assets/onboarding");
export const shortenedSearches = absolutePath("shortened-searches");
export const predefinedFilters = absolutePath("predefined-filters");
export const linkedInReactions = absolutePath("reactions/comments");
export const autocompleteValues = absolutePath("autocomplete/values");
export const promptCaptionsList = absolutePath("prompts/captions-list");
export const promptTemplatesList = absolutePath("prompts/templates-list");
export const autocompleteLanguages = absolutePath("autocomplete/languages");
export const autocompleteSearches = absolutePath("autocomplete/searches");
export const repliesList = (subFeatureName: string) =>
  `${constructSpecificEndpoint(getCurrentModuleName(), subFeatureName)}/list`;
export const repliesStats = (subFeatureName: string) =>
  `${constructSpecificEndpoint(getCurrentModuleName(), subFeatureName)}/stats`;

// Insights
export const dashboard = absolutePath("dashboards");
export const search = absolutePath("explorer/search");
export const compare = absolutePath("explorer/compare");
export const insights = absolutePath("explorer/insights");
export const kpiData = absolutePath("explorer/kpi-data");
export const comments = absolutePath("explorer/comments");
export const dashboardCSV = absolutePath("dashboards/csv");
export const notifications = absolutePath("notifications");
export const clientData = absolutePath("explorer/client-data");
export const aggregations = absolutePath("explorer/aggregations");
export const insightsPosts = absolutePath("explorer/insights/posts");
export const compareProjects = absolutePath("explorer/compare-projects");

// Care
export const assignToMe = absolutePath("care/assign");
export const workflows = absolutePath("workflows");
export const platformMediaImage = (platform: string) => absolutePath(`media/${platform}/image`);

// Moderation
export const moderationStreams = absolutePath("moderation/streams");
export const moderationHidingConfig = absolutePath("moderation/moderate/hiding-config"); // TODO: name change needed

// Publish
export const publishings = absolutePath("publishings");
export const instagramAssetInfo = (asset_id: string) =>
  absolutePath(`publishings/instagram/${asset_id}/asset-info`);
export const instagramProducts = (asset_id: string) =>
  absolutePath(`publishings/instagram/${asset_id}/products`);
export const youtubeCategories = (asset_id: string, countryCode: string) =>
  absolutePath(`publishings/youtube/${asset_id}/categories?countryCode=${countryCode}`);
export const youtubePlaylists = (asset_id: string) =>
  absolutePath(`publishings/youtube/${asset_id}/playlists`);

// Listen
export const listen = absolutePath("listen");
export const sources = absolutePath("listen/sources");
export const trendingHashtags = absolutePath("tiktok/trending-hashtags");
export const trendingHashtagsFilters = absolutePath("tiktok/trending-hashtags-filters");

// Managed
export const commentsList = (subFeatureName: string) => absolutePath(`care/${subFeatureName}/list`);
export const scheduledReports = absolutePath("documents/scheduled");

// Settings
export const stripe = absolutePath("stripe");
export const assets = absolutePath("assets");
export const settings = absolutePath("settings");
export const accounts = absolutePath("accounts");
export const document = absolutePath("documents");
export const generatePdf = absolutePath("documents/pdf");
export const staticData = absolutePath("static/data");
export const assetGroups = absolutePath("asset-group");
export const deleteToken = absolutePath("assets/onboarding/delete-token");
export const registerToken = absolutePath("assets/onboarding/register-token");
export const twitterRequestTokenUrl = absolutePath("assets/onboarding/twitter/request-token");
export const featureVisibilityFlags = absolutePath("user-feature-visibility-flags");
export const documentUrl = (id: string) => absolutePath(`documents/${id}/url`);
export const requestRemoval = (asset_id: string) =>
  absolutePath(`assets/request-removal/${asset_id}`);

// Login
export const password = absolutePath("users/password");
export const signUp = absolutePath("users/signup");
export const apiLog = absolutePath("logs");
export const submit = `${HUBSPOT_FORM_API}/submit`;
