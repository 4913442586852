import { ICustomTag } from "../../TagsDropdown/TagsDropdown.type";
import { CommentListType, IAiTag, ICommentTag, IReplyPhrase } from "@store/comments/types";
import { PostTypes } from "@store/replies/types";
import { IPrivateAttachment } from "../../CommentCard/cardBody/attachment/Attachment.type";

export interface IPrivateMessageCardProps {
  id: string;
  parentId: string;
  date?: string;
  author?: string;
  avatarSrc?: string;
  isHidden?: boolean;
  createdTime?: number;
  isOwned?: boolean;
  replyPhrase?: IReplyPhrase;
  message: string;
  messageTranslated?: string;
  attachments?: IPrivateAttachment[];
  customTagsByUser?: ICustomTag[];
  customTagsByAutomation?: ICustomTag[];
  aiTags?: IAiTag[];
  tags?: ICommentTag[];
  postType?: PostTypes;
  username: string;
  publicToPrivateCommentId?: string;
  listId?: CommentListType;
}

export const AVATAR_SIZE = 24;
export const DATE_FORMAT = "DD MMM, YYYY";
export const TIME_FORMAT = "hh:mm:ss A";
