import cn from "classnames/bind";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Row, Col } from "antd";
import PostsTableRowActions from "./PostsTableRowActions";
import PostView from "../../../_common/PostView/PostView";
import { ISortableTableRow } from "@bbdevcrew/bb_ui_kit_fe";
import PostModal from "../../../_common/Social/Post/PostModal";

import s from "./PostsTable.module.less";

import { IPost } from "../../../_common/Social/Post/Post.type";
import { getCommentPercentages, isMetricNotAvailable } from "./helpers";

const cx = cn.bind(s);

export type PostsTableRowProps = ISortableTableRow<IPost>;

const PostsTableRow = React.memo(({ data: row, index }: PostsTableRowProps) => {
  const { t } = useTranslation();

  const [postOpen, setPostOpen] = useState(false);

  const onClosePost = () => setPostOpen(false);
  const onOpenPostClick = () => setPostOpen(true);

  const sentimentColumns = (
    <>
      <Col className={s.bbColumn} span={3}>
        {row.comment_metrics.positive_comments} (
        {getCommentPercentages(
          row.comment_metrics.positive_comments,
          row.comment_metrics.total_comments,
        )}
        )
      </Col>
      <Col className={s.bbColumn} span={3}>
        {row.comment_metrics.negative_comments} (
        {getCommentPercentages(
          row.comment_metrics.negative_comments,
          row.comment_metrics.total_comments,
        )}
        )
      </Col>
    </>
  );

  const YTColumns = (
    <Col className={s.bbColumnNotAvailable} span={15}>
      {t("components:posts:table:notAvailable", {
        platform: row.platform_type.label,
      })}
    </Col>
  );

  const TWColumns = (
    <>
      {sentimentColumns}
      <Col className={s.bbColumnNotAvailable} span={9}>
        {t("components:posts:table:notAvailableYet", {
          platform: row.platform_type.label,
        })}
      </Col>
    </>
  );

  const defaultColumns = (
    <>
      {sentimentColumns}
      <Col
        className={cx(s.bbColumn, {
          [s.bbMetricNotAvailable]: isMetricNotAvailable(row.post_metrics?.total_reaches),
        })}
        span={2}
      >
        {row.post_metrics?.total_reaches}
      </Col>
      <Col className={s.bbColumn} span={2}>
        {row.post_metrics?.total_reactions}
      </Col>
      <Col className={s.bbColumn} span={2}>
        {row.post_metrics?.total_shares}
      </Col>
      <Col className={s.bbColumn} span={3}>
        {parseFloat(row.post_metrics?.engagement_rate.toFixed(4))}%
      </Col>
    </>
  );

  return (
    <Row
      data-cy="posts-table-row"
      className={cx(s.bbPostsTableRow, {
        [s.bbPostsTableRowColored]: index % 2 === 1,
      })}
    >
      <Col span={5} data-cy="post-column" className={s.bbColPost}>
        <PostView post={row} showPostTags imageSize={52} onPostOverlayClick={onOpenPostClick} />
      </Col>
      <Col className={s.bbColumn} span={2}>
        {row.comment_metrics.total_comments}
      </Col>
      {row.platform.name === "twitter"
        ? TWColumns
        : row.platform.name === "youtube"
          ? YTColumns
          : defaultColumns}
      <PostsTableRowActions
        postId={row.id}
        showAds={!!row.has_ads}
        onOpenPostClick={onOpenPostClick}
        className={cx(index % 2 === 1 ? [s.bbPostsTableRowColored] : [s.bbPostsTableRowWhite])}
      />

      <PostModal post={row} showPostTags isOpen={postOpen} onClose={onClosePost} />
    </Row>
  );
});

PostsTableRow.displayName = "PostsTableRow";

export default PostsTableRow;
