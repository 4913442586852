import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import React, { FC, Fragment, useState } from "react";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CommentsDownloadInfo.module.less";

import { DEFAULT_COMMENT_EXPORT_LIMIT } from "../helpers";

import { LoadingIcon } from "@assets/index";
import CommentsBoxStatic from "@assets/CommentsBox.svg";
import CommentsBoxDone from "@assets/CommentsBoxDone.svg";
import { CloseIcon, CheckIcon, ChevronUpIcon, ChevronDownIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface ICommentsDownloadInfoProps {
  open: boolean;
  loading: boolean;
  closeModal: () => void;
  downloadAgain: () => void;
  commentsDownloaded: boolean;
}

const cx = cn.bind(s);

const CommentsDownloadInfo: FC<ICommentsDownloadInfoProps> = ({
  open,
  loading,
  closeModal,
  downloadAgain,
  commentsDownloaded,
}) => {
  const { t } = useTranslation();

  const [isMinified, setIsMinified] = useState(false);

  return (
    <Fragment>
      {open ? (
        <div
          className={cx(s.bbCommentsDownloadInfo, {
            [s.bbCommentsDownloadInfoMinified]: isMinified,
          })}
        >
          <div className={s.bbHeader}>
            <div className={s.bbHeaderMain}>
              {loading ? (
                <>
                  <div className={s.bbHeaderMainTitle}>
                    <span className={s.bbLoading}>
                      <LoadingIcon />
                    </span>
                    <span>{t("components:comments:download:preparingComments")}</span>
                  </div>
                  {!isMinified ? (
                    <div className={s.bbSubHeader}>{t("components:comments:download:wait")}</div>
                  ) : null}
                </>
              ) : commentsDownloaded ? (
                <span>
                  <span className={s.bbCheck}>
                    <CheckIcon />
                  </span>
                  <span> {t("components:comments:download:commentsDonloaded")} </span>
                </span>
              ) : null}
            </div>
            <div className={s.bbActionIcons}>
              <div className={s.bbSmallIcon} onClick={() => setIsMinified(!isMinified)}>
                {isMinified ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </div>
              <div className={s.bbSmallIcon} onClick={() => closeModal()}>
                <CloseIcon />
              </div>
            </div>
          </div>
          {!isMinified ? (
            <div
              className={cx(s.bbCommentsDownloadContent, {
                [s.bbCommentsDownloadContentDownloading]: loading,
              })}
            >
              {loading ? (
                <>
                  <CommentsBoxStatic />
                  <div className={s.bbDisclaimer}>
                    {t("components:comments:download:exportInfo", {
                      limit: DEFAULT_COMMENT_EXPORT_LIMIT,
                    })}
                  </div>
                </>
              ) : commentsDownloaded ? (
                <>
                  <CommentsBoxDone />
                  <div className={s.bbDisclaimer}>
                    {t("components:comments:download:downloadFailed")}
                    <Button _type="link" onClick={() => downloadAgain()}>
                      {t("generic:tryAgain")}
                    </Button>
                  </div>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </Fragment>
  );
};

export default CommentsDownloadInfo;
