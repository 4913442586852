import { switchMap, mergeMap, map, filter, catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { StateObservable } from "redux-observable";

import * as actions from "./actions";
import { RootState } from "..";
import {
  FeatureVisibilityFlagsActions,
  IFeatureVisibilityFlag,
  IFeatureVisibilityOption,
} from "./types";

import { featureVisibilityFlags } from "@utils/paths";

import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

export const getFeatureVisibilityFlags = (
  action$: Observable<FeatureVisibilityFlagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getFeatureVisibilityFlagsAction)),
    switchMap(() => {
      return ajax
        .get<IFeatureVisibilityFlag[]>(
          featureVisibilityFlags,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getFeatureVisibilityFlagsSuccessAction(data)),
          catchError(e => handleError(e, actions.getFeatureVisibilityFlagsFailureAction)),
        );
    }),
  );

export const updateFeatureVisibilityFlags = (
  action$: Observable<FeatureVisibilityFlagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateFeatureVisibilityFlagsAction)),
    mergeMap(a => {
      return ajax
        .post(
          featureVisibilityFlags,
          { items: a.payload },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => actions.updateFeatureVisibilityFlagsSuccessAction()),
          catchError(e => handleError(e, actions.updateFeatureVisibilityFlagsFailureAction)),
        );
    }),
  );

export const getFeatureVisibilityOptions = (
  action$: Observable<FeatureVisibilityFlagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getFeatureVisibilityOptionsAction)),
    switchMap(() => {
      return ajax
        .get<IFeatureVisibilityOption[]>(
          `${featureVisibilityFlags}/visibility-options`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getFeatureVisibilityOptionsSuccessAction(data)),
          catchError(e => handleError(e, actions.getFeatureVisibilityOptionsFailureAction)),
        );
    }),
  );
