import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";

import { SortableTable } from "@bbdevcrew/bb_ui_kit_fe";

import TrendingHastagsTableRow from "./TrendingHastagsTableRow";

import { getTableColumns, parseValue, TABLE_PAGE_SIZE } from "./TiktokTrendingHashtags.helpers";
import { ISortableTableColumn, SortType } from "@bbdevcrew/bb_ui_kit_fe";
import {
  ITrendingHashtagsTableProps,
  ITrendingHashtagsTableSort,
  TrendingHashtagsColumnSortType,
} from "./TiktokTrendingHashtags.types";

const TrendingHashtagsTable = ({
  data = [],
  loading,
  onChangePage,
  page,
}: ITrendingHashtagsTableProps) => {
  const { t } = useTranslation();

  const [sort, setSort] = useState<ITrendingHashtagsTableSort>();

  const getData = useMemo(() => {
    const startIndex = (page - 1) * TABLE_PAGE_SIZE;
    const endIndex = startIndex + TABLE_PAGE_SIZE;

    const sortedData = sort
      ? [...data].sort((a, b) => {
          const valueA = parseValue(sort.field === "rank" ? a.rank.position : a[sort.field]);
          const valueB = parseValue(sort.field === "rank" ? b.rank.position : b[sort.field]);
          if (valueA === valueB) return 0;
          const comparison = valueA > valueB ? 1 : -1;
          return sort.order === "asc" ? comparison : -comparison;
        })
      : data;

    return sortedData.slice(startIndex, endIndex);
  }, [sort, page, data]);

  const onSort = (column: ISortableTableColumn, _sort?: SortType) => {
    if (!_sort) {
      setSort(undefined);
    } else {
      setSort({
        order: _sort,
        field: column.id_name as TrendingHashtagsColumnSortType,
      });
    }
  };

  return (
    <SortableTable
      data={getData}
      onSort={onSort}
      loading={loading}
      tableRow={TrendingHastagsTableRow}
      columns={getTableColumns()}
      pagination={{
        total: data.length,
        pageSize: TABLE_PAGE_SIZE,
        currentPage: page,
        onChangePage,
      }}
      emptyTableText={t("generic:emptyTable")}
      pageOutOfText={[
        t("components:sortableTable:pageOutOfPart1"),
        t("components:sortableTable:pageOutOfPart1"),
      ]}
    />
  );
};

export default TrendingHashtagsTable;
