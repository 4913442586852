import React from "react";
import { i18nextInstance } from "../../../../App";

import { getColumnInfo } from "./info/Info";
import { getColumnBullet } from "./bullet/Bullet";

import {
  ReachesTooltipContent,
  ReactionsTooltipContent,
  EngagementRateTooltipContent,
  SharesTooltipContent,
} from "./tooltips/Tooltips";

import { ISortableTableColumn } from "@bbdevcrew/bb_ui_kit_fe";

const TOOLTIP_WIDTH = 200;

export const getPostsTableColumns = (): ISortableTableColumn[] => {
  return [
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:post"),
      id_name: "created_time",
      colSpan: 5,
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:totalComments"),
      id_name: "total_comments",
      colSpan: 2,
      sort: "desc",
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:positiveComments"),
      id_name: "positive_comments",
      colSpan: 3,
      prefix: getColumnBullet("success"),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:negativeComments"),
      id_name: "negative_comments",
      colSpan: 3,
      prefix: getColumnBullet("danger"),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:totalReaches"),
      id_name: "total_reaches",
      colSpan: 2,
      suffix: getColumnInfo(<ReachesTooltipContent />, TOOLTIP_WIDTH),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:totalReactions"),
      id_name: "total_reactions",
      colSpan: 2,
      suffix: getColumnInfo(<ReactionsTooltipContent />, TOOLTIP_WIDTH),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:totalShares"),
      id_name: "total_shares",
      colSpan: 2,
      suffix: getColumnInfo(<SharesTooltipContent />, TOOLTIP_WIDTH),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:engagementRate"),
      id_name: "engagement_rate",
      colSpan: 3,
      suffix: getColumnInfo(<EngagementRateTooltipContent />, TOOLTIP_WIDTH),
    },
    {
      name: "",
      id_name: "actions",
      isSortable: false,
      colSpan: 2,
    },
  ];
};

export const getCommentPercentages = (commentAmount: number, totalComments: number) => {
  return totalComments ? `${((commentAmount / totalComments) * 100).toFixed(1)}%` : "0%";
};

export const isMetricNotAvailable = (metric: string | number) => metric === "N/A";
