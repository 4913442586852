import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Row, Col } from "antd";
import PostsTable from "./postsTable/PostsTable";
import { AlertInfo } from "@bbdevcrew/bb_ui_kit_fe";

import { meSuccessfulSelector } from "@store/me/selectors";
import { resetTriggerFilterClickWithoutUrlAction } from "@store/filters/actions";

import s from "./Posts.module.less";

import { IFilters } from "@store/filters/types";
import { getDefaultFilterValue } from "@utils/filters";
import { useCurrentFilters } from "@utils/useCurrentFilters";

const Posts: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterData = useCurrentFilters();
  const { platform_types } = getDefaultFilterValue();

  const [filters, setFilters] = useState<IFilters>({
    data_range_option: "last_30_days",
    platform_types,
  });
  const [showDisclaimer, setShowDisclaimer] = useState(
    JSON.parse(localStorage.getItem("PostsPageDisclaimer") || "true"),
  );

  const meFetched = useSelector(meSuccessfulSelector);

  const triggerFilterReset = useCallback(
    () => dispatch(resetTriggerFilterClickWithoutUrlAction()),
    [dispatch],
  );

  const onCloseBtnClick = () => {
    localStorage.setItem("PostsPageDisclaimer", "false");
    setShowDisclaimer(false);
  };

  const onFilter = (data: IFilters) => {
    delete data.sentiments;
    delete data.keyword_query_in_translation;
    setFilters(data);
    triggerFilterReset();
  };

  useEffect(() => {
    if (filterData) onFilter(filterData);
    // eslint-disable-next-line
  }, [filterData]);

  return meFetched ? (
    <>
      <div className={s.bbPostsContainer} data-cy="posts-container">
        <div className={s.bbPostsFiltersContainer} data-cy="posts-filters-container">
          {showDisclaimer && (
            <AlertInfo
              size="large"
              align="center"
              icon={<></>}
              data-cy="posts-disclaimer"
              className={s.bbPostsDisclaimerAlert}
              onClose={() => onCloseBtnClick()}
              message={t("components:posts:disclaimer:copy")}
              action={
                <a className={s.bbPostsDisclaimerAlertAction} onClick={() => onCloseBtnClick()}>
                  {t("components:posts:disclaimer:action")}
                </a>
              }
            />
          )}
        </div>
      </div>
      <Row>
        <Col xs={24} data-stonly-target="intelligence-posts_table">
          {filters && <PostsTable filters={filters} />}
        </Col>
      </Row>
    </>
  ) : null;
};

export default Posts;
