import React from "react";
import classNames from "classnames";

import Breadcrumbs from "../breadcrumbs";

import s from "./Header.module.less";

import { IHeaderProps } from "./Header.types";

export const Header: React.FC<IHeaderProps> = ({ children, className }) => {
  return (
    <div className={classNames(s.bbHeader, { className })}>
      <Breadcrumbs />
      {children}
    </div>
  );
};
