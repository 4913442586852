import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";

import { Form } from "antd";
import { Button, Input, Modal } from "@bbdevcrew/bb_ui_kit_fe";
import AssetsFilterSingle from "@components/_common/AppFilter/filters/Assets/AssetsSingle";

import { getPlatformIconBasicColor } from "@utils/platform";

import s from "./Sources.module.less";

import { HashIcon } from "@assets/index";
import { SourceModalProps } from "./Sources.types";
import { Source } from "@store/sources/types";

export const SourceModal = ({
  isOpen,
  isSubmitting,
  editingSource,
  onClose,
  onSubmit,
}: SourceModalProps) => {
  const { t } = useTranslation();

  const [isUnsavedChangesConfirmationOpen, setIsUnsavedChangesConfirmationOpen] = useState(false);
  const [form] = Form.useForm<Source>();
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const isEditMode = isOpen && !!editingSource;
  const title = isEditMode
    ? t("components:listen:sources:editSourceModal:title")
    : t("components:listen:sources:addSourceModal:title");
  const submitButtonText = isEditMode
    ? t("generic:save")
    : t("components:listen:sources:addSourceModal:submitButtonText");
  const initialValues = useMemo<Source | Record<string, never>>(
    () =>
      editingSource
        ? {
            ...editingSource,
            source_id: editingSource?.source_label.replace("#", ""),
          }
        : {},
    [editingSource],
  );

  useEffect(() => {
    form.setFieldsValue({
      asset_id: initialValues.asset_id,
      source_id: initialValues.source_id,
    });
  }, [form, initialValues.asset_id, initialValues.source_id]);

  const onConfirmUnsavedChanges = () => {
    setIsUnsavedChangesConfirmationOpen(false);
    onClose();
  };
  const onValuesChange = () => {
    const fieldsErrors = form.getFieldsError();
    const mappedErrors = fieldsErrors.reduce(
      (accErrors, error) => ({
        ...accErrors,
        ...(!!error.errors[0] && { [error.name[0]]: error.errors[0] }),
      }),
      {},
    );

    setErrors(mappedErrors);
  };

  return (
    <Modal
      centered
      responsive
      hideFooter
      onCancel={() => setIsUnsavedChangesConfirmationOpen(true)}
      open={isOpen}
      title={title}
      width={800}
    >
      {editingSource && (
        <div className={s.bbSourceModalPlatformIconWrapper}>
          Available for
          <div className={s.bbSourceModalPlatformIcon}>
            {getPlatformIconBasicColor(editingSource?.platform)}
          </div>
        </div>
      )}
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onSubmit}
        initialValues={initialValues}
        onFieldsChange={onValuesChange}
        requiredMark={false}
        scrollToFirstError
      >
        <Form.Item
          name="asset_id"
          data-cy="Source-asset"
          label={t("components:listen:sources:form:asset:label")}
          tooltip={t("components:listen:sources:form:asset:helperText")}
          className={s.bbSourceModalFormItem}
          rules={[
            {
              required: true,
              message: t("components:listen:sources:form:asset:error"),
            },
          ]}
        >
          <AssetsFilterSingle
            id="asset_ids"
            additionalLabelFields={["id"]}
            fetchFilters="assets_for_listen_source"
            className={s.bbSourceModalAssetsSelect}
            data-cy="Source-asset-input"
            customprops={{
              form,
              name: "asset_id",
            }}
            placeholder={t("components:listen:sources:form:asset:placeholder")}
            _size="md"
          />
        </Form.Item>
        <Form.Item
          name="source_id"
          data-cy="Source-label"
          label={t("components:listen:sources:form:hashtag:label")}
          className={s.bbSourceModalFormItem}
          rules={[
            {
              required: true,
              message: t("components:listen:sources:form:hashtag:error"),
            },
          ]}
        >
          <Input
            _size="md"
            name="source_id"
            data-cy="Source-label-input"
            placeholder={t("components:listen:sources:form:hashtag:placeholder")}
            className={s.bbSourceModalSourceLabelInput}
            hasError={!!errors.source_id}
            prefixEl={
              <div className={s.bbSourceModalSourceLabelInputPrefix}>
                <HashIcon />
              </div>
            }
          />
        </Form.Item>
        <Form.Item>
          <div className={s.bbSourceModalFooter}>
            <Button
              _type="secondary"
              type="button"
              onClick={() => setIsUnsavedChangesConfirmationOpen(true)}
            >
              {t("generic:cancel")}
            </Button>
            <Button _type="primary" type="submit" loading={isSubmitting}>
              {submitButtonText}
            </Button>
          </div>
        </Form.Item>
      </Form>

      <Modal
        centered
        responsive
        onCancel={() => setIsUnsavedChangesConfirmationOpen(false)}
        onOk={onConfirmUnsavedChanges}
        cancelLabel={t("components:listen:sources:unsavedChanges:cancelText")}
        confirmLabel={t("generic:discardChanges")}
        open={isUnsavedChangesConfirmationOpen}
        title={t("components:listen:sources:unsavedChanges:title")}
      >
        <p>{t("components:listen:sources:unsavedChanges:text")}</p>
      </Modal>
    </Modal>
  );
};
