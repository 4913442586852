import classNames from "classnames";
import { ListRef } from "rc-virtual-list/lib/List";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import AssignUser from "@containers/AssignUser/AssignUser";
import ActionHide from "../CommentCard/commentActions/predefinedActions/ActionHide";
import ActionStar from "../CommentCard/commentActions/predefinedActions/ActionStar";
import ActionLike from "../CommentCard/commentActions/predefinedActions/ActionLike";
import ActionDelete from "../CommentCard/commentActions/predefinedActions/ActionDelete";
import ActionArchive from "../CommentCard/commentActions/predefinedActions/ActionArchive";
import ActionReplyPrivately from "../CommentCard/commentActions/predefinedActions/ActionReplyPrivately"; // eslint-disable-line max-len

import { postShortenedSearchesSuccessfulSelector } from "@store/shortenedSearches/selectors";
import { assignCommentUserAction, updateCommentSentimentAction } from "@store/comments/actions";
import { meSelector } from "@store/me/selectors";

import s from "./MessageCard.module.less";

import { useAppFilters } from "@utils/appFilters";
import { IComment } from "@store/comments/types";
import useCommentActions from "@utils/useCommentActions";
import { IMessageActionsFooterProps } from "./MessageCard.type";
import { isLinkedinPlatform, isTrustpilotPlatform } from "@utils/platform";
import { useReplyModalView } from "@containers/ReplyModal/useReplyModalView";
import { useCommentExternalActionDropdownItems } from "./dropdowns/useCommentExternalActionDropdownItems"; // eslint-disable-line max-len

const MessageActions: FC<IMessageActionsFooterProps> = ({
  filters,
  comment,
  replyData,
  assigned_users,
  onClose,
  listId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listRef = React.useRef<ListRef>(null);
  const { handleChangeCategory } = useReplyModalView(replyData?.author_detail?.categories || []);

  const me = useSelector(meSelector);

  const {
    archiveComment,
    starComment,
    hideComment,
    deleteComment,
    likeComment,
    isLikeActionAvailable,
    isHideActionAvailable,
    isDeleteActionAvailable,
  } = useCommentActions(filters || {}, listId);

  const [isCommentInitiallyFocused, setIsCommentInitiallyFocused] = useState(false);
  const [shouldInitData, setShouldInitData] = useState(false);
  const [isFilteredAuthorsId, setIsFilteredAuthorsId] = useState(false);

  const { updateSearchDataAndUrl } = useAppFilters(shouldInitData);
  const postShortenedSearchesFetched = useSelector(postShortenedSearchesSuccessfulSelector);

  // Auto scroll list to bottom
  useEffect(() => {
    if (!isCommentInitiallyFocused && listRef.current && replyData) {
      listRef.current.scrollTo({
        align: "top",
        index: replyData.items.length - 1 || 0,
      });
      setIsCommentInitiallyFocused(true);
    }
    // eslint-disable-next-line
  }, [listRef, replyData?.items, isCommentInitiallyFocused]);

  useEffect(() => {
    if (postShortenedSearchesFetched && isFilteredAuthorsId) {
      setShouldInitData(true);
      onClose();
    }
  }, [postShortenedSearchesFetched, isFilteredAuthorsId, onClose]);

  const privatelyReplyToComment = (selectedComment: IComment) => {
    const privateMessagesCategory = replyData?.author_detail.categories.find(
      category => category.id === "private_messages",
    );

    if (privateMessagesCategory) handleChangeCategory(privateMessagesCategory, selectedComment);
  };

  const updateCommentSentiment = useCallback(
    (id, sentiment) => {
      dispatch(updateCommentSentimentAction(id, { sentiment }));
    },
    [dispatch],
  );
  const filterAuthorsId = (authorId: string) => {
    updateSearchDataAndUrl({ author_ids: [authorId] }, "id");
    setIsFilteredAuthorsId(true);
  };

  const onAssignUserChange = (value: string[], assigningCommentId: string) => {
    if (assigningCommentId) {
      dispatch(assignCommentUserAction(assigningCommentId, { assigned_users: value }, me?.id));
    }
  };

  const externalActionFunctions = {
    updateCommentSentiment,
    filterAuthorsId,
  };

  const commentExternalActions = useCommentExternalActionDropdownItems({
    comment,
    actionFunctions: externalActionFunctions,
    showCommentLink: !me?.is_demo_mode_active,
  });

  const hasPublicToPrivateReply = useMemo(
    () => !!replyData?.items.find(item => item?.private_replied_public_comment_id === comment?.id),
    [replyData, comment],
  );

  const showHideOrDeleteAction = useMemo(
    () =>
      comment &&
      !isTrustpilotPlatform(comment.platform_type.id) &&
      (isDeleteActionAvailable(comment) || isHideActionAvailable(comment)),
    [comment, isDeleteActionAvailable, isHideActionAvailable],
  );

  return (
    <>
      {comment ? (
        <div className={s.bbMessageCardActions}>
          {showHideOrDeleteAction && (
            <span className={s.bbMessageActionFlex1}>
              {isLinkedinPlatform(comment.platform_type.id) && isDeleteActionAvailable(comment) ? (
                <ActionDelete
                  hideLabel
                  comment={comment}
                  onDeleteClick={deleteComment}
                  className={s.bbMessageActionButtonClass}
                />
              ) : isHideActionAvailable(comment) ? (
                <ActionHide
                  hideLabel
                  useShortcuts
                  comment={comment}
                  onHideClick={hideComment}
                  className={s.bbMessageActionButtonClass}
                />
              ) : null}
            </span>
          )}
          {comment.actions.private_reply_capability.capability !== "not_applicable" && (
            <span className={s.bbMessageActionFlex2}>
              <ActionReplyPrivately
                comment={comment}
                className={s.bbMessageActionTriggerClass}
                onPrivateReplyClick={privatelyReplyToComment}
                tooltip={comment.actions.private_reply_capability.tooltip}
                disabled={
                  comment.actions.private_reply_capability.capability === "disabled" ||
                  hasPublicToPrivateReply
                }
              />
            </span>
          )}
          <span className={s.bbMessageActionsFooterAssignUser}>
            <AssignUser
              value={assigned_users || []}
              hideTriggerPlaceholder={true}
              triggerPlaceholder={t("generic:assign")}
              onChange={value => onAssignUserChange(value, comment.id)}
              triggerClassName={classNames(
                s.bbMessageActionTriggerClass,
                s.bbMessageActionsFooterAssignUserTrigger,
                {
                  [s.bbMessageActionsFooterAssignUserTriggerSelected]: assigned_users?.length,
                },
              )}
            />
          </span>
          {isLikeActionAvailable(comment) && (
            <span className={s.bbMessageActionFlex1}>
              <ActionLike
                comment={comment}
                onLikeClick={likeComment}
                className={s.bbMessageActionButtonClass}
              />
            </span>
          )}
          <span className={s.bbMessageActionFlex1}>
            <ActionStar
              useShortcuts
              comment={comment}
              onStarClick={starComment}
              className={s.bbMessageActionButtonClass}
            />
          </span>
          <span className={s.bbMessageActionFlex1}>
            <ActionArchive
              useShortcuts
              comment={comment}
              onArchiveClick={archiveComment}
              className={s.bbMessageActionButtonClass}
            />
          </span>
          {!!commentExternalActions.length && (
            <span>
              <ActionsDropdown
                items={commentExternalActions}
                triggerClass={s.bbMessageCardActionsDropdown}
              />
            </span>
          )}
        </div>
      ) : null}
    </>
  );
};

export default MessageActions;
