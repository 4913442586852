export const GET_FEATURE_VISIBILITY_FLAGS = "SETTINGS/GET_FEATURE_VISIBILITY_FLAGS";
export const GET_FEATURE_VISIBILITY_FLAGS_SUCCESS = "SETTINGS/GET_FEATURE_VISIBILITY_FLAGS_SUCCESS";
export const GET_FEATURE_VISIBILITY_FLAGS_FAILURE = "SETTINGS/GET_FEATURE_VISIBILITY_FLAGS_FAILURE";

export const UPDATE_FEATURE_VISIBILITY_FLAGS = "SETTINGS/UPDATE_FEATURE_VISIBILITY_FLAGS";
export const UPDATE_FEATURE_VISIBILITY_FLAGS_SUCCESS =
  "SETTINGS/UPDATE_FEATURE_VISIBILITY_FLAGS_SUCCESS";
export const UPDATE_FEATURE_VISIBILITY_FLAGS_FAILURE =
  "SETTINGS/UPDATE_FEATURE_VISIBILITY_FLAGS_FAILURE";

export const GET_FEATURE_VISIBILITY_OPTIONS = "SETTINGS/GET_FEATURE_VISIBILITY_OPTIONS";
export const GET_FEATURE_VISIBILITY_OPTIONS_SUCCESS =
  "SETTINGS/GET_FEATURE_VISIBILITY_OPTIONS_SUCCESS";
export const GET_FEATURE_VISIBILITY_OPTIONS_FAILURE =
  "SETTINGS/GET_FEATURE_VISIBILITY_OPTIONS_FAILURE";
