import { useDispatch, useSelector } from "react-redux";
import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames/bind";

import { Loading } from "@bbdevcrew/bb_ui_kit_fe";
import MostCommentedPost from "@components/insights/overview/mostCommentedPosts/mostCommentedPost/MostCommentedPost"; // eslint-disable-line max-len

import {
  getTrendingPostsSelector,
  getTrendingPostsSelectorPending,
  getTrendingPostsSelectorSuccessful,
} from "@store/overview/selectors";
import { getTrendingPostsAction } from "@store/overview/actions";
import { filterDataSelector } from "@store/filters/selectors";

import s from "./MostCommentedPosts.module.less";

import { IMostCommentedPostsProps } from "./MostCommentedPosts.types";

export const MostCommentedPosts: React.FC<IMostCommentedPostsProps> = ({ onLoad, className }) => {
  const dispatch = useDispatch();

  const [activePlatformIndex, setActivePlatformIndex] = useState(0);

  const trendingPostsList = useSelector(getTrendingPostsSelector);
  const trendingPostsLoading = useSelector(getTrendingPostsSelectorPending);
  const trendingPostsListFetched = useSelector(getTrendingPostsSelectorSuccessful);
  const filters = useSelector(filterDataSelector);

  const generateTrendingPostsList = useCallback(
    () => dispatch(getTrendingPostsAction(filters || {})),
    [dispatch, filters],
  );

  useEffect(() => {
    if (!trendingPostsListFetched && !trendingPostsLoading) {
      generateTrendingPostsList();
    }
  }, [trendingPostsListFetched, trendingPostsLoading, generateTrendingPostsList]);

  useEffect(() => {
    if (trendingPostsListFetched) {
      const activeIndex = trendingPostsList.findIndex(({ is_active }) => is_active);
      setActivePlatformIndex(activeIndex);
      onLoad?.();
    }
  }, [trendingPostsList, trendingPostsListFetched, onLoad]);

  return trendingPostsLoading ? (
    <Loading isLoading />
  ) : trendingPostsList[activePlatformIndex]?.items?.length ? (
    <div className={classNames(s.bbMostCommentedPosts, className)}>
      {trendingPostsList[activePlatformIndex].items
        ?.slice(0, 5)
        .map((trendingPost, index) => (
          <MostCommentedPost
            key={index}
            postData={trendingPost}
            className={s.bbMostCommentedPostsCard}
          />
        ))}
    </div>
  ) : null;
};
