import classNames from "classnames";
import { useTranslation } from "react-i18next";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Avatar } from "antd";
import { ReplyPhrase } from "./ReplyPhrase";
import { ReplyDateTime } from "./ReplyDateTime";
import { Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import Attachments from "../../CommentCard/cardBody/attachment/Attachment";
import CustomTagsList from "../../CommentCard/customTags/CustomTagsList";
import CommentTagsList from "../../CommentCard/commentTags/CommentTagsList";
import BrandBastionTags from "../../CommentCard/brandBastionTags/BBTags";
import CustomTagsDropdown from "../../CommentCard/customTags/CustomTagsDropdown";
import AiHighlightTags from "../../CommentCard/aiHighlightTags/AiHighlightTags";

import { deleteCommentTagAction, postCommentTagAction } from "@store/commentTags/actions";
import { canManageBBTagsSelector } from "@store/me/selectors";
import { deleteCustomTagFromCommentAction } from "@store/customTags/actions";

import audioStyles from "./../../CommentCard/cardBody/attachment/variants/AudioAttachment.module.less"; // eslint-disable-line max-len
import s from "./PrivateMessageCard.module.less";

import { getInitials } from "../helpers";
import { ICommentTag } from "@store/comments/types";
import { isStoryAttachment } from "@utils/comments";
import { getReplyDate, getStoryPreviewMessage } from "./PrivateMessageCard.helpers";
import { AVATAR_SIZE, IPrivateMessageCardProps } from "./PrivateMessageCard.type";
import { useReplyModalContext } from "@containers/ReplyModal/ReplyModalContext";
import { PublicToPrivateReplyDisclaimer } from "../../Reply/publicToPrivateReplyDisclaimer";

import { WarningIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { ReplyAuthor } from "./ReplyAuthor";
import { ArrowTopRightIcon } from "@assets/index";

export const PrivateMessageCard: FC<IPrivateMessageCardProps> = ({
  id,
  date,
  author,
  message,
  parentId,
  isHidden,
  avatarSrc,
  isOwned,
  replyPhrase,
  createdTime,
  attachments = [],
  messageTranslated = "",
  publicToPrivateCommentId,
  tags,
  aiTags,
  customTagsByUser,
  customTagsByAutomation,
  postType,
  username,
  listId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canManageBBTag = useSelector(canManageBBTagsSelector);

  const { isMessagesTranlated } = useReplyModalContext();

  const authorExists = !!author;
  const type = isOwned ? "highlighted" : "default";
  const position = isOwned ? "right" : "left";
  const messageLeft = position === "left";
  const messageRight = position === "right";
  const hasAttachments = !!attachments.length;
  const isFallbackAttachment = attachments?.[0]?.type === "fallback";
  const isUnsupportedMediaAttachment = attachments?.[0]?.description === "unsupported_media";
  const isMessageStoryAttachment = hasAttachments && isStoryAttachment(attachments[0].description);
  const hasTags =
    !!tags?.length ||
    !!aiTags?.length ||
    !!customTagsByUser?.length ||
    !!customTagsByAutomation?.length;

  const handleDeleteCustomTag = (tagId?: string) => {
    if (tagId) {
      dispatch(
        deleteCustomTagFromCommentAction({
          id: tagId,
          comment_id: id,
          parent_comment_id: parentId,
        }),
      );
    }
  };

  const handleDeleteBBTag = (tagId: string) => {
    dispatch(
      deleteCommentTagAction({
        tagId,
        commentId: id,
        parentCommentId: parentId,
        listId,
      }),
    );
  };

  const handleAddBBTag = (tag: ICommentTag) => {
    dispatch(
      postCommentTagAction({
        tagId: tag.id,
        commentId: id,
        parentCommentId: parentId,
        listId,
      }),
    );
  };

  const userAvatar = (
    <Avatar src={avatarSrc} size={AVATAR_SIZE} className={s.bbPrivateMessageCardUser}>
      {!avatarSrc && author ? getInitials(author) : undefined}
    </Avatar>
  );

  const getMessageWithAttachment = () => {
    const attachment = attachments[0];

    return (
      <>
        {isFallbackAttachment ? (
          <div>
            <Tooltip title={t("components:reply:fallbackLinkTooltip")}>
              <span
                className={s.bbPrivateMessageCardFallbackAttachment}
                onClick={() => window.open(attachment.url, "_blank")}
              >
                {attachment.url}
              </span>
            </Tooltip>

            {` ${message}`}
          </div>
        ) : (
          <>
            {isMessageStoryAttachment && (
              <span className={s.bbStoryPreviewMessage}>
                {getStoryPreviewMessage(attachments[0].description, !!isOwned)}
              </span>
            )}
            <Attachments
              createdTime={createdTime}
              attachments={attachments || []}
              isStoryAttachment={isMessageStoryAttachment}
              className={s[`bbMessageCardAttachment-${position}`]}
              audioClassName={classNames(
                s.bbPrivateMessageContentRegular,
                s[`bbMessageCardTextual-${type}`],
                s[`bbMessageCardPosition-${position}`],
                audioStyles[`bbAudioTrack-${position}`],
              )}
            />
          </>
        )}
        {isMessageStoryAttachment && !!message.trim() && (
          <div
            className={classNames(
              s.bbPrivateMessageContent,
              s.bbPrivateMessageContentRegular,
              s[`bbMessageCardPosition-${position}`],
              s[`bbMessageCardTextual-${type}`],
            )}
          >
            {message}
          </div>
        )}
      </>
    );
  };

  const renderPublicToPrivateDisclaimer = (commentId: string) => (
    <PublicToPrivateReplyDisclaimer
      commentId={commentId}
      text={t("components:reply:replyPrivately:disclaimerResponded")}
    />
  );

  return (
    <>
      {!!publicToPrivateCommentId
        ? renderPublicToPrivateDisclaimer(publicToPrivateCommentId)
        : null}
      <div
        data-testid="message-card"
        className={classNames("flex flex-col", s[`bbPrivateMessageCard-${position}`], {
          [s.bbMessageCardOffsetLeft]: !authorExists && messageLeft,
          [s.bbMessageCardOffsetRight]: !authorExists && messageRight,
        })}
      >
        <div
          className={classNames(
            s.bbPrivateMessageContainer,
            s[`bbPrivateMessageContainer-${position}`],
          )}
        >
          {authorExists && messageLeft ? userAvatar : null}
          <div>
            <div className={s.bbPrivateMessageWrapper}>
              <div
                className={classNames(
                  s.bbPrivateMessageContent,
                  s[`bbMessageCardPosition-${position}`],
                  s[`bbMessageCardTextual-${type}`],
                  {
                    [s.bbPrivateMessageContentTransparent]:
                      hasAttachments && !isFallbackAttachment && !isUnsupportedMediaAttachment,
                    [s.bbPrivateMessageContentRegular]: !(
                      hasAttachments &&
                      !isFallbackAttachment &&
                      !isUnsupportedMediaAttachment
                    ),
                  },
                )}
              >
                <Tooltip title={!!date ? getReplyDate(date) : undefined}>
                  {attachments[0]?.type === "post" ? (
                    <Button
                      _size="sm"
                      className={s.bbPrivateMessagePostLink}
                      onClick={() => window.open(attachments[0].url, "_blank")}
                    >
                      {t("components:comments:actions:showPost")}
                      <ArrowTopRightIcon />
                    </Button>
                  ) : postType === "post_share" ? (
                    <a href={message} target="_blank" rel="noreferrer">
                      {message}
                    </a>
                  ) : (
                    <p data-testid="message">
                      {isMessagesTranlated && messageTranslated ? (
                        messageTranslated
                      ) : hasAttachments && attachments[0].description === "unsupported_media" ? (
                        <> </>
                      ) : hasAttachments ? (
                        getMessageWithAttachment()
                      ) : (
                        message
                      )}
                    </p>
                  )}
                </Tooltip>
                <ReplyPhrase
                  replyPhrase={replyPhrase}
                  className={hasAttachments ? s.bbAttachmentReplyPhrase : undefined}
                />
              </div>
              {!isOwned && (
                <div className={s.bbPrivateMessageCardTags}>
                  <CustomTagsDropdown
                    commentId={id}
                    parentCommentId={parentId}
                    hasTags={!!customTagsByUser || !!customTagsByAutomation}
                    customTagsAutomated={customTagsByAutomation || []}
                    customTagsByUser={customTagsByUser || []}
                    type="custom"
                    listId={listId}
                  />
                  {canManageBBTag && (
                    <div className={s.bbPrivateMessageCardBBTags}>
                      <BrandBastionTags
                        tags={tags || []}
                        onAddTag={handleAddBBTag}
                        onDeleteTag={handleDeleteBBTag}
                        type="custom"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {authorExists && position === "right" ? userAvatar : null}
        </div>

        {hasAttachments && attachments[0].description === "unsupported_media" && (
          <div
            className={classNames(s.bbPrivateMessageError, {
              [s.bbPrivateMessageErrorLeft]: messageLeft,
              [s.bbPrivateMessageErrorRight]: messageRight,
            })}
          >
            <WarningIcon />
            {t("components:reply:unsupportedMedia")}
          </div>
        )}

        {!isOwned && hasTags && (
          <div
            className={classNames(s.bbPrivateMessageCardTags, {
              [s.bbPrivateMessageCardTagsWithAuthor]: authorExists,
            })}
          >
            <CommentTagsList tags={tags || []} commentId={id} />

            <AiHighlightTags tags={aiTags} />

            <CustomTagsList
              customTagsAutomated={customTagsByAutomation}
              customTagsByUser={customTagsByUser}
              onDeleteTag={handleDeleteCustomTag}
            />
          </div>
        )}

        {authorExists && (
          <ReplyDateTime
            date={date}
            isHidden={isHidden}
            className={classNames(s[`bbPrivateMessageReplyDateTime-${position}`])}
            author={
              <ReplyAuthor
                author={author}
                postType={postType}
                isOwned={isOwned}
                username={username}
                hasAttachments={hasAttachments}
                attachments={attachments}
              />
            }
          />
        )}
      </div>
    </>
  );
};
