import React, { FC } from "react";

import { HelpIcon, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Info.module.less";

interface IInfoProps {
  tooltipWidth?: number;
  tooltipContent: string | JSX.Element;
}

const Info: FC<IInfoProps> = ({ tooltipContent, tooltipWidth }) => (
  <span className={s.bbInfoTooltip}>
    <Tooltip
      placement="top"
      title={tooltipContent}
      overlayInnerStyle={{
        minWidth: tooltipWidth,
      }}
    >
      <HelpIcon />
    </Tooltip>
  </span>
);

export const getColumnInfo = (tooltipContent: string | JSX.Element, tooltipWidth?: number) => (
  <Info tooltipContent={tooltipContent} tooltipWidth={tooltipWidth} />
);
