import {
  GET_FEATURE_VISIBILITY_FLAGS,
  GET_FEATURE_VISIBILITY_FLAGS_SUCCESS,
  GET_FEATURE_VISIBILITY_FLAGS_FAILURE,
  UPDATE_FEATURE_VISIBILITY_FLAGS,
  UPDATE_FEATURE_VISIBILITY_FLAGS_SUCCESS,
  UPDATE_FEATURE_VISIBILITY_FLAGS_FAILURE,
  GET_FEATURE_VISIBILITY_OPTIONS,
  GET_FEATURE_VISIBILITY_OPTIONS_SUCCESS,
  GET_FEATURE_VISIBILITY_OPTIONS_FAILURE,
} from "./actionTypes";
import { createAction } from "typesafe-actions";
import { IFeatureVisibilityFlag, IFeatureVisibilityOption } from "./types";

export const getFeatureVisibilityFlagsAction = createAction(GET_FEATURE_VISIBILITY_FLAGS)<void>();
export const getFeatureVisibilityFlagsSuccessAction = createAction(
  GET_FEATURE_VISIBILITY_FLAGS_SUCCESS,
)<IFeatureVisibilityFlag[]>();
export const getFeatureVisibilityFlagsFailureAction = createAction(
  GET_FEATURE_VISIBILITY_FLAGS_FAILURE,
)<void>();

export const updateFeatureVisibilityFlagsAction = createAction(UPDATE_FEATURE_VISIBILITY_FLAGS)<
  IFeatureVisibilityFlag[]
>();
export const updateFeatureVisibilityFlagsSuccessAction = createAction(
  UPDATE_FEATURE_VISIBILITY_FLAGS_SUCCESS,
)<void>();
export const updateFeatureVisibilityFlagsFailureAction = createAction(
  UPDATE_FEATURE_VISIBILITY_FLAGS_FAILURE,
)<string>();

export const getFeatureVisibilityOptionsAction = createAction(
  GET_FEATURE_VISIBILITY_OPTIONS,
)<void>();
export const getFeatureVisibilityOptionsSuccessAction = createAction(
  GET_FEATURE_VISIBILITY_OPTIONS_SUCCESS,
)<IFeatureVisibilityOption[]>();
export const getFeatureVisibilityOptionsFailureAction = createAction(
  GET_FEATURE_VISIBILITY_OPTIONS_FAILURE,
)<void>();
