import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import DownloadReportModal from "@components/_common/DownloadReportModal";
import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./DownloadButton.module.less";

import {
  IShareReportForm,
  ReportSectionType,
} from "@components/_common/DownloadReportModal/DownloadReportModal.types";
import {
  openReportPreview,
  useShortenedSearchesId,
} from "@components/managed/Transparency/ShareReportButton/ShareReportButton.helpers";

import { DownloadIcon } from "@assets/index";

const reportSections = ["overview", "sentiment_details", "topics"];
const reportSectionsMap = {
  overview: ["sentiment_preview", "message_types"],
  sentiment_details: ["sentiment_extended"],
  topics: ["topics"],
};

export const DownloadButton = () => {
  const { t } = useTranslation();
  const shortenedSearchesId = useShortenedSearchesId();

  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const downloadReport = useCallback(
    ({ name, sections }: IShareReportForm) => {
      if (shortenedSearchesId) {
        const selectedSections = Object.entries(sections)
          .filter(([, value]) => value.checked)
          .map(([key]) => key)
          .flatMap(
            section => reportSectionsMap[section as keyof typeof reportSectionsMap] || [],
          ) as ReportSectionType[];
        openReportPreview({
          path: "insights/pdf_export/overview",
          id: shortenedSearchesId,
          name,
          sections: selectedSections,
        });
        setShowDownloadModal(false);
      }
    },
    [shortenedSearchesId, setShowDownloadModal],
  );

  return (
    <>
      <Button
        className={s.bbDownloadReportButton}
        _type="on-surface"
        _size="sm"
        onClick={() => setShowDownloadModal(true)}
      >
        <DownloadIcon />
        {t("generic:download")}
      </Button>
      <DownloadReportModal
        open={showDownloadModal}
        onCancel={() => setShowDownloadModal(false)}
        onFinish={downloadReport}
        sections={reportSections as ReportSectionType[]}
      />
    </>
  );
};
